//CORE
import { useMemo, useState } from "react";
import { Button, Modal, Spin, Typography } from "antd";
import { useDispatch } from "react-redux";
import { Content } from "antd/es/layout/layout";

//CUSTOM
import { LogoutModalWrapper } from "./LogoutModal.style";
import Api from "Helpers/ApiHandler";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { logoutUser } from "Redux/Auth/Actions";

const LogoutModal = (props) => {
  const API = useMemo(() => new Api(), []);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      const response = await API.get(API_URL.LOGOUT);

      if (response.status === CODES.SUCCESS) {
        dispatch(logoutUser());
        props.closeModal();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open
      width={350}
      onCancel={() => props.closeModal()}
      footer={null}
      closable={false}
    >
      <Spin spinning={isLoading}>
        <LogoutModalWrapper>
          <Typography className="title">Logout</Typography>
          <Typography className="text">
            Are you sure you want to logout?
          </Typography>
          <Content className="action-btn">
            <Button type="default" onClick={() => props.closeModal()}>
              Cancel
            </Button>
            <Button
              danger
              type="primary"
              className="bg-danger-btn"
              loading={isLoading}
              onClick={() => handleLogout()}
            >
              Logout
            </Button>
          </Content>
        </LogoutModalWrapper>
      </Spin>
    </Modal>
  );
};

export default LogoutModal;
