import { COLORS } from "Styles/Constants";
import { borderRadius, transition } from "Styles/StyleUtils";
import styled from "styled-components";

export const TopBarWrapper = styled.div`
  .isomorphicTopbar {
    height: 70px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 25px;
    margin: 0;
    z-index: 1000;
    ${transition()};

    &.collapsed {
      /* padding: 0 109px 0 31px; */

      @media only screen and (max-width: 767px) {
        padding: 0px 15px;
      }
    }

    .isoLeft {
      display: flex;
      align-items: center;
      margin: 0;

      @media only screen and (max-width: 767px) {
        /* margin: 0 20px 0 0; */
      }

      .triggerBtn {
        width: 24px;
        height: 100%;
        display: -webkit-inline-flex;
        display: -ms-inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 0;
        outline: 0;
        position: relative;
        cursor: pointer;

        .menu-icon {
          font-size: 20px;
        }

        &:before {
          /* content: "\f20e"; */
          font-family: "Ionicons";
          font-size: 26px;
          color: inherit;
          line-height: 0;
          position: absolute;
        }
      }
    }

    .isoRight {
      display: flex;
      align-items: center;

      li {
        margin-left: 0;
        /* margin-right: 35px; */
        cursor: pointer;
        line-height: normal;
        position: relative;
        display: inline-block;

        @media only screen and (max-width: 360px) {
          margin-left: 0;
          margin-right: 25px;
        }

        &:last-child {
          margin: 0;
        }

        i {
          font-size: 24px;
          color: ${COLORS.HEADING};
          line-height: 1;
        }

        .isoIconWrapper {
          position: relative;
          line-height: normal;

          span {
            font-size: 12px;
            color: #fff;
            background-color: #f1f3f6;
            width: 20px;
            height: 20px;
            display: -webkit-inline-flex;
            display: -ms-inline-flex;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 20px;
            position: absolute;
            top: -8px;
            left: 10px;
            right: inherit;
            ${borderRadius("50%")};
          }
        }

        &.isoMail {
          .isoIconWrapper {
            span {
              background-color: ${COLORS.ORANGE};
            }
          }
        }

        &.isoNotify {
          .isoIconWrapper {
            span {
              background-color: ${COLORS.PRIMARY};
            }
          }
        }

        &.isoMsg {
          .isoIconWrapper {
            span {
              background-color: ${COLORS.PURPLE};
            }
          }
        }

        &.isoCart {
          .isoIconWrapper {
            span {
              background-color: ${COLORS.PINK};
            }
          }
        }

        &.isoUser {
          .isoImgWrapper {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-color: ${COLORS.WHITE_GREY};
            ${borderRadius("50%")};

            img {
              height: 100%;
              object-fit: cover;
            }

            .userActivity {
              width: 10px;
              height: 10px;
              display: block;
              background-color: ${COLORS.SUCCESS};
              position: absolute;
              bottom: 0;
              right: 3px;
              border: 1px solid #ffffff;
              ${borderRadius("50%")};
            }
          }
        }
      }
    }
  }
`;
