//CORE
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Spin, Table, Tooltip } from "antd";

//TYPE
import {
  LayoutContentStyle,
  LayoutContentWrapper,
  PageContentWrapper,
  PageHeader,
} from "Components/Common/Common.style";

//ICON
import { EditOutlined, EyeOutlined } from "@ant-design/icons";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import { PAGINATION } from "Helpers/Constants";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { getFieldKeyValue, handleFeedBackStatus, sortNumber } from "Helpers/Utils";
import FeedbackModal from "./Model/FeedbackModal";
import ViewFeedback from "./Drawer/ViewFeedback";

const Feedback = () => {
  const API = useMemo(() => new Api(), []);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    perPage: PAGINATION.PER_PAGE,
    filter: {
      field: "id",
      order: "ASC",
    },
    search: {
      searchField: "",
      searchBy: "",
    },
  });
  const [feedbackData, setFeedbackData] = useState({
    data: [],
    totalRecords: 0,
  });
  const [resetData, setResetData] = useState(false);
  const [feedBackModal, setFeedBackModal] = useState({
    data: null,
    visible: false,
  });
  const [feedbackDrawer, setFeedbackDrawer] = useState({
    data: null,
    visible: false,
  });

  const getFeedbackData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await API.post(API_URL.FEEDBACK.GET, {
        data: {
          pageNumber: paginationInfo.currentPage,
          totalRecordPerPage: paginationInfo.perPage,
          orderBy: paginationInfo.filter.field,
          order: paginationInfo.filter.order,
          searchField: paginationInfo.search.searchField,
          searchBy: paginationInfo.search.searchBy,
        },
      });
      if (response.status === CODES.SUCCESS) {
        setIsLoading(false);
        setFeedbackData({
          data: response?.data?.list,
          totalRecords: response.data.totalElements,
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [API, paginationInfo]);

  const handleOpenModal = (data) => {
    setFeedBackModal((prev) => {
      return { ...prev, visible: true, data };
    });
  };

  const handleCloseModal = (shouldUpdate = false) => {
    setFeedBackModal((prev) => {
      return { ...prev, data: null, visible: false };
    });
    shouldUpdate && setResetData((prev) => !prev);
  };

  const handleOpenDrawer = (data) => {
    setFeedbackDrawer((prev) => {
      return { ...prev, data, visible: true };
    });
  };

  const handleCloseDrawer = (shouldUpdate = false) => {
    setFeedbackDrawer((prev) => {
      return { ...prev, data: null, visible: false };
    });
    shouldUpdate && setResetData((prev) => !prev);
  };

  const tableFiltersUpdated = (pagination, filters, sorter) => {
    let sorterOrder = "ASC";
    if (sorter.field)
      switch (sorter.order) {
        case "ascend":
          sorterOrder = "ASC";
          break;
        case "descend":
          sorterOrder = "DESC";
          break;
        default:
          sorterOrder = "ASC";
          sorter.field = "id";
          break;
      }

    const searchValues = getFieldKeyValue(filters);

    setPaginationInfo((prev) => {
      return {
        ...prev,
        currentPage: pagination.current ? pagination.current : 1,
        perPage: pagination?.pageSize || PAGINATION.PER_PAGE,
        filter: {
          field: sorter.field || "id",
          order: sorterOrder || "Asc",
        },
        search: {
          searchField: searchValues.filterKey,
          searchBy: searchValues.filterValue,
        },
      };
    });
  };

  const feedbackListUI = () => {
    const paginationConfig = {
      pageSize: paginationInfo.perPage,
      total: feedbackData.totalRecords,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} of ${total} records`,
      current: paginationInfo.currentPage,
      showSizeChanger: true,
    };

    const columns = [
      {
        title: "Sr.No",
        dataIndex: "id",
        key: "id",
        rowScope: "row",
        fixed: "left",
        width: 70,
        render: (text, record, index) => (
          <span>
            {(paginationInfo.currentPage - 1) * paginationInfo.perPage +
              index +
              1}
          </span>
        ),
      },
      {
        title: "Full Name",
        dataIndex: "fullName",
        key: "fullName",
        width: 150,
        sorter: (a, b) => sortNumber(a, b, "fullName"),
      },
      {
        title: "Email Id",
        dataIndex: "emailId",
        key: "emailId",
        width: 200,
        sorter: (a, b) => sortNumber(a, b, "emailId"),
      },
      {
        title: "Feedback",
        dataIndex: "feedback",
        key: "feedback",
        width: 250,
        render: (text, record, index) => (
          <PageContentWrapper dangerouslySetInnerHTML={{ __html: text }} />
        ),
      },
      {
        title: "Status",
        dataIndex: "feedbackStatusId",
        key: "feedbackStatusId",
        width: 150,
        // sorter: (a, b) => sortNumber(a, b, "emailId"),
        render: (text, record, index) => handleFeedBackStatus(text),
      },
      {
        title: "Action",
        key: "action",
        fixed: "right",
        width: 100,
        render: (text, record, index) => (
          <div className="action-button-container">
            <div className="item">
              <Tooltip placement="top" title="Edit">
                <Button
                  className="btn-info btn-sm ant-btn-sm"
                  onClick={() => handleOpenModal(record)}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            </div>
            <div className="item">
              <Tooltip placement="top" title="View">
                <Button
                  className="ant-btn-sm"
                  onClick={() => handleOpenDrawer(record)}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>
            </div>
          </div>
        ),
      },
    ];

    return (
      <Table
        dataSource={feedbackData.data}
        columns={columns}
        rowKey={"id"}
        style={{ width: "100%" }}
        loading={{ indicator: <Spin />, spinning: isLoading }}
        scroll={{ x: 700 }}
        onChange={tableFiltersUpdated}
        pagination={
          feedbackData.totalRecords > PAGINATION.PER_PAGE
            ? paginationConfig
            : false
        }
      />
    );
  };

  useEffect(() => {
    getFeedbackData();
  }, [getFeedbackData, resetData]);

  return (
    <LayoutContentWrapper>
      <LayoutContentStyle>
        <div className="title-container">
          <PageHeader>Feedback</PageHeader>
        </div>
        <div className="table-container">{feedbackListUI()}</div>
        {feedBackModal.visible && (
          <FeedbackModal
            modalData={feedBackModal?.data}
            closeModal={handleCloseModal}
          />
        )}
        {feedbackDrawer.visible && (
          <ViewFeedback
            drawerData={feedbackDrawer}
            closeDrawer={handleCloseDrawer}
          />
        )}
      </LayoutContentStyle>
    </LayoutContentWrapper>
  );
};

export default Feedback;
