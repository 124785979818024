import { COLORS } from "Styles/Constants";
import styled from "styled-components";

export const UserNotificationWrapper = styled.div`
  .email-field-wrapper {
    margin-bottom: 10px;
  }

  .error {
    margin: 0;
    height: 14px;
    font-size: 14px;
    color: ${COLORS.ERROR};
  }

  .tag-item {
    background-color: ${COLORS.GREY_SHADE};
    display: inline-block;
    border-radius: 30px;
    height: 30px;
    padding: 4px 4px 4px 8px;
    align-items: center;
    margin: 5px 5px 5px 0;
  }

  .tag-item > .button {
    background-color: #fff;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    line-height: 1;
  }
`;
