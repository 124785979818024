import { createContext, useEffect } from "react";
import io from "socket.io-client";

const SocketContext = createContext();

const credentials = { id: JSON.parse(localStorage.getItem("userInfo"))?.userId || "" };

const SocketProvider = ({ children }) => {
  console.log("process.env.REACT_APP_WEBSOCKET_URL", process.env.REACT_APP_WEBSOCKET_URL)
  // const socketTextMsg = io('http://18.132.83.214:5291/', {
  const socketTextMsg = io(process.env.REACT_APP_WEBSOCKET_URL, {
    transports: ["websocket", "polling", "flashsocket"],
    // auth: { offset: "checkingHandshake" },
    query: credentials,
  });

  useEffect(() => {
    return () => {
      socketTextMsg.disconnect();
    };
  }, [socketTextMsg]);

  return (
    <SocketContext.Provider value={socketTextMsg}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketProvider, SocketContext };
