import { DrawerWrapper } from "Components/Common/Common.style";
import { TRANSACTION_TYPE } from "Helpers/Constants";
import { Card, Drawer, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import moment from "moment";
import React from "react";

const ViewTransactionLog = (props) => {
  const { drawerData } = props;

  const handleTransactionType = (transactionType) => {
    switch (transactionType) {
      case TRANSACTION_TYPE.INVESTMENT:
        return "Investment";
      case TRANSACTION_TYPE.INTEREST_ADDED:
        return "Interest Added";
      case TRANSACTION_TYPE.WITHDRAWAL:
        return "Withdrawal";
      default:
        return "N/A";
    }
  };

  return (
    <Drawer
      open={drawerData.visible}
      title="View Transaction Log"
      size="large"
      onClose={() => props.closeDrawer()}
      closable
    >
      <DrawerWrapper $keyWidth={135}>
        <Card className="card">
          <Typography className="title">Transaction Log Details</Typography>
          <Content className="fields">
            <Typography className="key">Plane Name</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.investmentPlanName || "N/A"}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Fund Name</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.fundName || "N/A"}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Total Investment</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.currentTotalInvestedAmount || "N/A"}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">current value</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.currentTotalReturnAmount || "N/A"}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Investment Start</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.investmentStartOn
                ? moment(drawerData.data?.investmentStartOn).format("DD/MM/yy")
                : "N/A"}
            </Typography>
          </Content>
        </Card>
        {drawerData?.data?.investmentStatement &&
          drawerData?.data?.investmentStatement
            .sort(
              (a, b) =>
                new Date(b.investmentStartOn) - new Date(a.investmentStartOn)
            )
            .map((investment) => (
              <Card className="card" key={investment?.transactionId}>
                <Content className="fields">
                  <Typography className="key">Transaction Type</Typography>
                  <Typography className="separator">:</Typography>
                  <Typography className="value">
                    {handleTransactionType(investment?.transactionTypeId)}
                  </Typography>
                </Content>
                <Content className="fields">
                  <Typography className="key">Amount</Typography>
                  <Typography className="separator">:</Typography>
                  <Typography className="value">
                    {investment?.amount || 0}
                  </Typography>
                </Content>
                <Content className="fields">
                  <Typography className="key">Transaction Date</Typography>
                  <Typography className="separator">:</Typography>
                  <Typography className="value">
                    {investment?.createdOn
                      ? moment(investment?.createdOn).format("DD/MM/yy")
                      : "N/A"}
                  </Typography>
                </Content>
              </Card>
            ))}
      </DrawerWrapper>
    </Drawer>
  );
};

export default ViewTransactionLog;
