//CORE
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, DatePicker, Form, Input, Spin, notification } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";

//WRAPPER
import {
  FormWrapper,
  LayoutContentStyle,
  LayoutContentWrapper,
  PageHeader,
} from "Components/Common/Common.style";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import { FORM_PARAMS } from "Helpers/Constants";
import { ProfileWrapper } from "./AdminProfile.style";
import { API_URL, URL_DASHBOARD } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { disabledFutureDate } from "Helpers/Utils";

const { TextArea } = Input;

const AdminProfile = () => {
  const formRef = useRef(null);
  const API = useMemo(() => new Api(), []);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [adminData, setAdminData] = useState({});

  const getAdminInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await API.get(API_URL.ADMIN.GET);
      if (response.status === CODES.SUCCESS) {
        setAdminData(response?.data);
        setIsLoading(false);
        formRef.current?.setFieldValue("fullName", response?.data?.fullName);
        formRef.current?.setFieldValue(
          "dob",
          dayjs(response?.data?.dob, "YYYY-MM-DD")
        );
        formRef.current?.setFieldValue(
          "countryCode",
          response?.data?.countryCode
        );
        formRef.current?.setFieldValue(
          "mobileNumber",
          response?.data?.mobileNumber
        );
        formRef.current?.setFieldValue("emailId", response?.data?.emailId);
        formRef.current?.setFieldValue(
          "cmsUserAddress",
          response?.data?.cmsUserAddress
        );
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [API]);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await API.put(API_URL.ADMIN.EDIT, {
        data: {
          ...values,
          dob: moment(values.dob.$d).format("YYYY-MM-DD"),
          userId: adminData?.id,
        },
      });
      if (response.status === CODES.SUCCESS) {
        notification.success({
          message: "Success",
          description: response?.message,
        });
        setIsLoading(false);
        navigate(URL_DASHBOARD);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAdminInfo();
  }, [getAdminInfo]);

  return (
    <LayoutContentWrapper>
      <LayoutContentStyle>
        <ProfileWrapper>
          <div className="title-container">
            <PageHeader>Admin Profile</PageHeader>
          </div>
          <Spin spinning={isLoading}>
            <FormWrapper
              ref={formRef}
              onFinish={handleSubmit}
              scrollToFirstError
              labelAlign="left"
              labelCol={{ span: FORM_PARAMS.LABEL_COL_SPAN }}
              labelWrap
            >
              <Form.Item
                name="fullName"
                key="fullName"
                label="Full Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your full name",
                  },
                ]}
              >
                <Input placeholder="Enter full name" />
              </Form.Item>
              <Form.Item
                name="dob"
                key="dob"
                label="Date of Birth"
                rules={[
                  {
                    required: true,
                    message: "Please enter date of birth",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={disabledFutureDate}
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  placeholder="Enter date of birth"
                />
              </Form.Item>
              <Form.Item
                name="countryCode"
                key="countryCode"
                label="Country Code"
                rules={[
                  {
                    required: true,
                    message: "Country code is required",
                  },
                ]}
                className="country-code"
              >
                <Input
                  placeholder="+ 01"
                  maxLength={4}
                  onChange={(e) =>
                    formRef.current?.setFieldValue(
                      "countryCode",
                      e?.target?.value.replace(/[^0-9+]/g, "")
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                name="mobileNumber"
                key="mobileNumber"
                label="Mobile no"
                className="mobile-no"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone no",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value.length < 10) {
                        return Promise.reject(
                          new Error("Phone number must be 10 digits")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Enter mobile no"
                  maxLength={10}
                  onChange={(e) =>
                    formRef.current?.setFieldValue(
                      "mobileNumber",
                      e?.target?.value.replace(/[^0-9]/g, "")
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                name={"emailId"}
                key={"emailId"}
                label="Email"
                // rules={[{ required: true, message: "Please enter your email" }]}
              >
                <Input
                  placeholder="Enter email id"
                  disabled={true}
                  className="email-input-field"
                />
              </Form.Item>
              <Form.Item
                name={"cmsUserAddress"}
                key={"cmsUserAddress"}
                label="Headquarter Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter your headquarter address",
                  },
                ]}
              >
                <TextArea
                  maxLength={250}
                  showCount
                  rows={4}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                />
              </Form.Item>
              <Button
                disabled={isLoading}
                onClick={() => formRef.current.submit()}
                type="primary"
              >
                Submit
              </Button>
            </FormWrapper>
          </Spin>
        </ProfileWrapper>
      </LayoutContentStyle>
    </LayoutContentWrapper>
  );
};

export default AdminProfile;
