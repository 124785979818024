import styled from "styled-components";

export const EmailModalWrapper = styled.div`
  padding: 20px 0 0 0;
  
  .form {
    max-height: 55vh;
    overflow: auto;

    .var-wrapper {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      .type-text {
        font-weight: bold;
        background-color: rgb(210, 205, 205);
        color: rgb(49, 41, 14);
        width: max-content;
        padding: 5px;
        border-radius: 5px;
      }
    }

    .email-editor-item {
      width: inherit;
      margin: 0;
      padding: 0;

      .email-editor {
        width: 400px;
      }
    }
  }
`;