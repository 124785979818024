//COMPONENT
import { Dashboard } from "Components/Pages/Dashboard";
import { Email } from "Components/Pages/Email";
import { UserNotification } from "Components/Pages/UserNotification";
import { RegisteredUser } from "Components/Pages/UserManagement/Registered";
import { VerifiedUser } from "Components/Pages/UserManagement/Verified";
import { AdminProfile } from "Components/Pages/AdminProfile";
import { Subscription } from "Components/Pages/Subscription";
import { TransactionLog } from "Components/Pages/TransactionLog";
import { StaticPages } from "Components/Pages/StaticPages";
import { WithdrawalRequest } from "Components/Pages/WithdrawalRequest";
import { SocialMedia } from "Components/Pages/SocialMedia";
import { Notification } from "Components/Pages/Notification";
import { Chat } from "Components/Pages/Chat";
import { UserChat } from "Components/Pages/Chat/UserChat";
import { Feedback } from "Components/Pages/Feedback";

//PATH
const {
  URL_DASHBOARD,
  URL_EMAIL_TEMPLATE,
  URL_USER_NOTIFICATION,
  URL_REGISTERED_USER,
  URL_VERIFIED_USER,
  URL_ADMIN_PROFILE,
  URL_SUBSCRIPTION_PLAN,
  URL_TRANSACTION_LOG,
  URL_STATIC_PAGE,
  URL_WITHDRAWAL_REQUEST,
  URL_SOCIAL_MEDIA,
  URL_NOTIFICATION,
  URL_CHAT,
  URL_FEEDBACK,
} = require("Helpers/Paths");

const RoutesList = [
  {
    path: URL_DASHBOARD,
    exact: true,
    Component: <Dashboard />,
    hasChildren: false,
  },
  {
    path: URL_REGISTERED_USER,
    exact: true,
    Component: <RegisteredUser />,
    hasChildren: false,
  },
  {
    path: URL_VERIFIED_USER,
    exact: true,
    Component: <VerifiedUser />,
    hasChildren: false,
  },
  {
    path: URL_EMAIL_TEMPLATE,
    exact: true,
    Component: <Email />,
    hasChildren: false,
  },
  {
    path: URL_USER_NOTIFICATION,
    exact: true,
    Component: <UserNotification />,
    hasChildren: false,
  },
  {
    path: URL_ADMIN_PROFILE,
    exact: true,
    Component: <AdminProfile />,
    hasChildren: false,
  },
  {
    path: URL_SUBSCRIPTION_PLAN,
    exact: true,
    Component: <Subscription />,
    hasChildren: false,
  },
  {
    path: URL_TRANSACTION_LOG,
    exact: true,
    Component: <TransactionLog />,
    hasChildren: false,
  },
  {
    path: URL_STATIC_PAGE,
    exact: true,
    Component: <StaticPages />,
    hasChildren: false,
  },
  {
    path: URL_WITHDRAWAL_REQUEST,
    exact: true,
    Component: <WithdrawalRequest />,
    hasChildren: false,
  },
  {
    path: URL_SOCIAL_MEDIA,
    exact: true,
    Component: <SocialMedia />,
    hasChildren: false,
  },
  {
    path: URL_NOTIFICATION,
    exact: true,
    Component: <Notification />,
    hasChildren: false,
  },
  {
    path: URL_CHAT,
    exact: true,
    Component: <Chat />,
    hasChildren: false,
  },
  {
    path: `${URL_CHAT}/:userName`,
    // exact: true,
    Component: <UserChat />,
    hasChildren: false,
  },
  {
    path: URL_FEEDBACK,
    exact: true,
    Component: <Feedback />,
    hasChildren: false,
  },
];

export default RoutesList;
