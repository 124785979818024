//CORE
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Input, Spin, Typography, notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

//ICONS
import { ArrowLeftOutlined } from "@ant-design/icons";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import { UserChatWrapper } from "./UserChat.style";
import { SocketContext } from "Context/Socket/SocketContext";
import { API_URL, SOCKET_EVENTS } from "Helpers/Paths";
import { CHAT_FILE_TYPE, CHAT_MSG_SEND_FROM, MSG_STATUS } from "Helpers/Constants";
import CODES from "Helpers/StatusCodes";
import { handleFileType } from "Helpers/Utils";
import TextMessage from "./Message/Text/TextMessage";
import PdfMessage from "./Message/Pdf/PdfMessage";
import ImageMessage from "./Message/Img/ImageMessage";
import FilePreview from "./Preview/FilePreview";

const DATE_FORMAT = "DD-MMM-YYYY";

const UserChat = () => {
  const API = useMemo(() => new Api(), []);
  const navigation = useNavigate();
  const location = useLocation();
  const socketTextMsg = useContext(SocketContext);
  const msgListInnerRef = useRef(null);
  const attachmentInputRef = useRef(null);

  const { msgId, msgReceiverId, userName } = location?.state;
  const userId = JSON.parse(localStorage.getItem("userInfo")).userId;
  const adminFullName = JSON.parse(localStorage.getItem("userInfo")).fullName;

  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 0,
    perPage: 100,
  });
  const [msgList, setMsgList] = useState({
    data: [],
    totalRecords: 0,
  });
  const [msgState, setMsgState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [attachedFiled, setAttachedFiled] = useState(null);

  const handleMsgInput = (e) => {
    //send msg on enter key press
    if (e.keyCode === 13) {
      handleClick();
    }
    return null;
  };

  const handleClick = async () => {
    //send msg
    if (attachedFiled) {
      setIsLoading(true);
      const data = new FormData();
      if (attachedFiled) {
        data.append("attachmentFile", attachedFiled);
      }
      const jsonData = {
        message: msgState,
        senderId: userId,
        receiverId: msgReceiverId,
        isSendFromAdmin: true,
        threadId: msgId,
        fileType: attachedFiled ? handleFileType(attachedFiled.name) : "TXT",
      };
      if (attachedFiled) jsonData["fileName"] = attachedFiled.name;
      data.append(
        "sendMessageRequestDTOString",
        new Blob([JSON.stringify(jsonData)], {
          type: "application/json",
        })
      );
      const response = await API.post(API_URL.CHAT.SEND_MSG, {
        data,
        isMultipart: true,
      });
      if (response.status === CODES.SUCCESS) {
        handelResponse(response?.data);
        // if (msgList.data.find((msg) => msg?.chatDate === "Today")) {
        //   setMsgList((prev) => ({
        //     ...prev,
        //     data: [...prev.data, response?.data],
        //     totalRecords: prev?.totalRecords + 1,
        //   }));
        // } else {
        //   setMsgList((prev) => ({
        //     ...prev,
        //     data: [
        //       ...prev.data,
        //       {
        //         type: "day",
        //         date: moment().format(DATE_FORMAT),
        //         id: moment().format(DATE_FORMAT),
        //         chatDate: "Today",
        //         messageTime: new Date(moment().format(DATE_FORMAT)).getTime(),
        //       },
        //       response?.data,
        //     ],
        //     totalRecords: prev?.totalRecords + 1,
        //   }));
        // }
        setMsgState("");
        setAttachedFiled(null)
        msgListInnerRef.current?.scrollIntoView({ behavior: "smooth" });
        setIsLoading(false);
      }
    } else if (!!msgState.trim()) {
      socketTextMsg.emit(
        SOCKET_EVENTS.SEND_MSG,
        {
          message: msgState,
          senderId: userId,
          receiverId: msgReceiverId,
          isSendFromAdmin: true,
          threadId: msgId,
          fileType: "TXT",
          fileName: "",
        },
        (response) => {
          // console.log("🚀 ~ handleClick ~ response:", response);
          handelResponse(response);
          // handelResponse(response?.body?.data);
          setMsgState("");
          setAttachedFiled(null);
          msgListInnerRef.current?.scrollIntoView({ behavior: "smooth" });
          setIsLoading(false);
          // setMsgList((prev) => ({
          //   ...prev,
          //   data: [...prev.data, response?.body?.data],
          // }));
        }
      );
    }
  };

  const handelResponse = (response) => {
    if (msgList.data.find((msg) => msg?.chatDate === "Today")) {
      setMsgList((prev) => ({
        ...prev,
        data: [...prev.data, response],
        totalRecords: prev?.totalRecords + 1,
      }));
    } else {
      setMsgList((prev) => ({
        ...prev,
        data: [
          ...prev.data,
          {
            type: "day",
            date: moment().format(DATE_FORMAT),
            id: moment().format(DATE_FORMAT),
            chatDate: "Today",
            messageTime: new Date(moment().format(DATE_FORMAT)).getTime(),
          },
          response,
        ],
        totalRecords: prev?.totalRecords + 1,
      }));
    }
  };

  const handleScrollPagination = () => {
    //pagination
    if (msgListInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = msgListInnerRef.current;
      if (
        scrollTop + scrollHeight - 10 < clientHeight && // call api when we are about to reach top
        msgList?.data.length < msgList.totalRecords
      ) {
        setPaginationInfo((prev) => ({
          ...prev,
          currentPage: prev.currentPage + 1,
        }));
      }
    }
  };

  const handelFileAttachment = (event) => {
    if (event?.target?.files[0].size / 1000 < 50000) {
      // validation for file size larger than 50mb
      setAttachedFiled(event?.target?.files[0]);
    } else {
      notification.warning({
        message: "Warning",
        description:
          "File size can't be greater than 50mb. try again with small file",
      });
    }
  };

  const groupedDays = (messages) => {
    //group msg which are send on same day
    return messages.reduce((acc, el, i) => {
      const messageDay = moment(el.messageTime).format(DATE_FORMAT);
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
  };

  const generateItems = useCallback((messages) => {
    //generate msg with group days
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days).sort(
      (x, y) => moment(y, DATE_FORMAT).unix() - moment(x, DATE_FORMAT).unix()
    );
    var today = moment().format(DATE_FORMAT); //to test that given date is today
    var yesterday = moment().subtract(1, "day").format(DATE_FORMAT); //to test that given date is yesterday
    const items = sortedDays.reduce((acc, date, i) => {
      const sortedMessages = days[date].sort(
        (x, y) => new Date(y.messageTime) - new Date(x.messageTime)
      );
      if (moment(date, DATE_FORMAT).isSame(today, "d"))
        return acc.concat([
          ...sortedMessages,
          {
            type: "day",
            date,
            id: date,
            chatDate: "Today",
            messageTime: new Date(date).getTime(),
          },
        ]);
      if (moment(date, DATE_FORMAT).isSame(yesterday, "d"))
        return acc.concat([
          ...sortedMessages,
          {
            type: "day",
            date,
            id: date,
            chatDate: "Yesterday",
            messageTime: new Date(date).getTime(),
          },
        ]);
      return acc.concat([
        ...sortedMessages,
        {
          type: "day",
          date,
          id: date,
          chatDate: date,
          messageTime: new Date(date).getTime(),
        },
      ]);
    }, []);
    return items;
  }, []);

  const handleMarkAsRead = useCallback(() => {
    if (
      msgList.totalRecords !== 0 &&
      msgList.data.find(
        (item) =>
          item.messageStatus === MSG_STATUS.DELIVERED &&
          item.sendFrom !== CHAT_MSG_SEND_FROM.ADMIN
      )
    ) {
      // SEND MARK AS READ STATUS
      const data = {
        threadId: msgId,
        messageId: msgList.data[0].id,
        isReadByAdmin: true,
      };
      // message/updatedMessages
      socketTextMsg.emit(
        SOCKET_EVENTS.MESSAGE.LAST_READ,
        data,
        (response) => {}
      );
    }
  }, [msgList, msgId, socketTextMsg]);

  useEffect(() => {
    //LISTEN MARK AS READ MSG ID
    socketTextMsg.on(SOCKET_EVENTS.MESSAGE.UPDATE_MSG, (response) => {
      if (
        //IF THERE IS MSG AND IT IS UN-READ
        !!msgList.data.length &&
        msgList.data.find(
          (item) => item.messageStatus === MSG_STATUS.DELIVERED
        ) &&
        !!response?.messageIdsList
      ) {
        const msgIdsList = response?.messageIdsList;
        const msgListData = msgList.data.map((item1) => {
          const matchingItem = msgIdsList.find((item2) => item2 === item1?.id);
          if (matchingItem) {
            return { ...item1, messageStatus: MSG_STATUS.SEEN };
          }
          return item1;
        });
        setMsgList((prev) => ({ ...prev, data: msgListData }));
      }
    });

    return () => {
      socketTextMsg.off(SOCKET_EVENTS.MESSAGE.UPDATE_MSG);
    };
  }, [socketTextMsg, msgList]);

  useEffect(() => {
    //get msg list
    const dataToSend = {
      threadId: msgId,
      page: {
        limit: paginationInfo.perPage,
        pageId: paginationInfo.currentPage,
      },
      lastId: "",
      requestFromAdmin: true,
    };
    setIsLoading(true);
    socketTextMsg.emit(SOCKET_EVENTS.LIST.MSG, dataToSend, (data) => {
      setMsgList((prev) => {
        const arr =
          paginationInfo.currentPage === 0
            ? generateItems(data?.body?.data?.messageDataList)
            : prev.data.concat(
                generateItems(data?.body?.data?.messageDataList)
              );
        return {
          data: [...new Map(arr.map((item) => [item["id"], item])).values()],
          totalRecords: data?.body?.data?.totalRecords,
        };
      });
      setIsLoading(false);
    });
  }, [paginationInfo, socketTextMsg, msgId, generateItems]);

  useEffect(() => {
    //listen msg from app
    socketTextMsg.open();
    socketTextMsg.on(SOCKET_EVENTS.GET_MSG, (data) => {
      setMsgList((prev) => ({
        ...prev,
        data: [...prev.data, data],
        totalRecords: prev?.totalRecords + 1,
      }));
      msgListInnerRef.current?.scrollIntoView({ behavior: "smooth" });
    });
    return () => {
      socketTextMsg.off(SOCKET_EVENTS.GET_MSG);
      socketTextMsg.close();
    };
  }, [socketTextMsg, adminFullName, userId]);

  useEffect(() => {
    handleMarkAsRead();
  }, [handleMarkAsRead]);

  return (
    <Spin spinning={isLoading}>
      <UserChatWrapper>
        <div className="content flex f-column">
          <div className="user-content flex">
            <ArrowLeftOutlined
              className="back-icon"
              onClick={() => navigation(-1)}
            />
            <Typography className="user-name">{userName}</Typography>
            {/* <Tooltip className="close-btn-tooltip" title="close chat">
            <Button className="ant-btn-sm" shape="circle" type="danger">
              <i className="ion-android-close close-icon" />
            </Button>
          </Tooltip> */}
          </div>
          <div
            className="container"
            ref={msgListInnerRef}
            onScroll={handleScrollPagination}
          >
            <div className="msg-content">
              {msgList?.data
                .sort((a, b) => b.messageTime - a.messageTime)
                .map((item) => (
                  <div className="flex f-column msg-wrapper" key={item?.id}>
                    {item?.type ? ( // msg group title
                      <>
                        <div className="date-group-wrapper flex f-h-center">
                          <p className="date-group-text">{item?.chatDate}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        {(item?.fileType === CHAT_FILE_TYPE.TXT || //only text msg
                          (item?.fileType && item?.fileType.trim()) === "") && (
                          <TextMessage item={item} />
                        )}
                        {(item?.fileType === CHAT_FILE_TYPE.JPEG || //img file
                          item?.fileType === CHAT_FILE_TYPE.JPG ||
                          item?.fileType === CHAT_FILE_TYPE.PNG) && (
                          <ImageMessage item={item} />
                        )}
                        {item?.fileType === CHAT_FILE_TYPE.PDF && ( //pdf file
                          <PdfMessage item={item} />
                        )}
                        <Typography //msg time
                          className={`msg-time ${
                            item?.sendFrom === CHAT_MSG_SEND_FROM.ADMIN
                              ? "admin-time"
                              : "user-time"
                          }`}
                        >
                          {new Date(item?.messageTime).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            }
                          )}
                        </Typography>
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <div className="input-content flex">
            <div className="input-wrapper">
              {attachedFiled && (
                <FilePreview
                  file={attachedFiled}
                  removeFile={() => setAttachedFiled(null)}
                />
              )}
              <Input
                placeholder="Type your message here"
                className="input-msg"
                value={msgState}
                onChange={(e) => setMsgState(e?.target?.value)}
                onKeyDown={handleMsgInput}
                suffix={
                  <i
                    className="ion-android-attach suffix-icon"
                    onClick={() => attachmentInputRef?.current?.click()}
                  />
                }
              />
            </div>
            <input //file input
              type="file"
              accept="image/png, image/jpeg, image/jpg, application/pdf"
              ref={attachmentInputRef}
              style={{ display: "none" }}
              onChange={handelFileAttachment}
            />
            <div className="send-wrapper" onClick={handleClick}>
              <i className="ion-ios-paperplane send-icon" />
            </div>
          </div>
        </div>
      </UserChatWrapper>
    </Spin>
  );
};

export default UserChat;
