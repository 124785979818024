import { COLORS } from "Styles/Constants";

export const AntComponentTheme = {
  token: {
    colorPrimary: COLORS.PRIMARY,
    colorPrimaryHover: COLORS.PRIMARY_LIGHT,
    colorError: COLORS.ERROR,
    colorErrorHover: COLORS.ERROR_DARK,
  },
  components: {
    Button: {
      borderRadius: 4,
      // fontSize: 18,
      // boxShadow: 'none'
    },
    Select: {
      borderRadius: 2,
      borderRadiusLG: 2,
      borderRadiusSM: 2,
    },
    Modal: {
      borderRadiusLG: 2,
      borderRadiusSM: 2,
      wireframe: true
    },
    Input: {
      borderRadius: 2,
      borderRadiusLG: 2,
      borderRadiusSM: 2,
    },
    DatePicker: {
      borderRadius: 2,
      borderRadiusLG: 2,
      borderRadiusSM: 2,
    },
    Menu: {
      iconSize: 18,
      collapsedIconSize: 18
    },
    Segmented: {
      itemSelectedBg: COLORS.PRIMARY,
      itemSelectedColor: COLORS.WHITE
    }
  },
};
