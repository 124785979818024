//CORE
import { useMemo, useRef, useState } from "react";
import { Button, Form, Input, Modal, Spin, notification } from "antd";

//API
import Api from "Helpers/ApiHandler";
import { FORM_PARAMS, REGEX } from "Helpers/Constants";
import { FormWrapper } from "Components/Common/Common.style";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { useEffect } from "react";

const EditTransactionLog = (props) => {
  const { modalData } = props;
  const API = useMemo(() => new Api(), []);
  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await API.patch(API_URL.UPDATE_INVESTMENT_LOG, {
        data: {
          id: modalData?.data?.id,
          ...values,
        },
      });
      if (response.status === CODES.SUCCESS) {
        handleClose(true, response?.data);
        notification.success({
          message: "Success",
          description: response?.message,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = (updateFlag = false, data = null) => {
    props.closeModal(updateFlag, data);
  };

  useEffect(() => {
    formRef?.current?.setFieldValue(
      "currentTotalReturnAmount",
      modalData?.data?.currentTotalReturnAmount
    );
  }, [modalData?.data]);

  return (
    <Modal
      open
      title={"Edit Transaction Log"}
      okButtonProps={{ disabled: isLoading }}
      onCancel={() => handleClose()}
      footer={[
        <Button key="back" type="default" onClick={() => handleClose()}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => formRef.current?.submit()}
        >
          Update
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <FormWrapper
          ref={formRef}
          onFinish={handleSubmit}
          scrollToFirstError
          labelAlign="left"
          labelCol={{ span: FORM_PARAMS.LABEL_COL_SPAN_L }}
          labelWrap
        >
          <Form.Item
            name="currentTotalReturnAmount"
            key="currentTotalReturnAmount"
            label="Current Value"
            rules={[
              {
                required: true,
                message: "Please your total current value",
              },
            ]}
            onChange={(e) =>
              formRef.current?.setFieldValue(
                "currentTotalReturnAmount",
                e?.target?.value.replace(REGEX.NUMBER, "")
              )
            }
          >
            <Input placeholder="Enter your current value" />
          </Form.Item>
        </FormWrapper>
      </Spin>
    </Modal>
  );
};

export default EditTransactionLog;
