import { DASHBOARD_KEY } from "Helpers/Constants";
import { ACTION_TYPES } from "./Actions";
import { getSideBarCurrentTab } from "Helpers/Utils";

const initState = {
  collapsed: window.innerWidth > 1220 ? false : true,
  openDrawer: false,
  current: !!getSideBarCurrentTab().length
    ? getSideBarCurrentTab()
    : [DASHBOARD_KEY],
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.HIDE_SIDEBAR:
      return { ...state, collapsed: true };
    case ACTION_TYPES.SHOW_SIDEBAR:
      return { ...state, collapsed: false };
    case ACTION_TYPES.TOGGLE_SIDEBAR:
      return { ...state, collapsed: !state.collapsed };
    case ACTION_TYPES.TOGGLE_DRAWER:
      return { ...state, openDrawer: !state.openDrawer };
    case ACTION_TYPES.SIDEBAR_ACTIVE:
      return { ...state, current: action.current };
    default:
      return state;
  }
};

export default Reducer;
