//CORE
import axios from "axios";
import { notification } from "antd";

//CUSTOM
import { logoutUser } from "Redux/Auth/Actions";
import { store } from "Redux/Store";
import CODES from "./StatusCodes";

const METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch",
};


// const BASEURL = "https://sr-investment-socket.apps.openxcell.dev/sr-investment/";
const BASEURL = process.env.REACT_APP_BASE_URL;

class Api {
  isLoggedInCMS = false;
  userData = {};
  baseURL;

  constructor() {
    this.baseURL = BASEURL;
    this.getAuthenticationInfo();
  }

  getAuthenticationInfo() {
    if (localStorage.getItem("isLoggedInCMS")) {
      this.isLoggedInCMS = true;
      this.userData = { accessToken: localStorage.getItem("accessToken") };
    }
  }

  // URL FOR API
  // REFER SAMPLE JSON AT BOTTOM FOR DATA VALUES
  get(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.GET, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  post(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.POST, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  put(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PUT, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  delete(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.DELETE, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  patch(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PATCH, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  api(method, url, data) {
    return new Promise((resolve, reject) => {
      let axiosConfig = {};
      axiosConfig.method = method;
      axiosConfig.url = this.baseURL + url;

      axiosConfig.headers = this.setHeaders(data);
      if (data) {
        if (data?.params) axiosConfig.params = data.params;

        if (data?.data) axiosConfig.data = data.data;

        if (data?.data?.includeResponseTypeAsArrayBuffer)
          axiosConfig.responseType = "arraybuffer";
      }

      axios(axiosConfig)
        .then((response) => {
          if (response?.data) resolve(response.data);
        })
        .catch((error) => {
          if (
            error?.response?.data?.status ===
            CODES.UNAVAILABLE_FOR_LEGAL_REASONS
          )
            return reject(error);
          if (error?.response?.data?.status === CODES.UNAUTHORIZED)
            store.dispatch(logoutUser());
          notification.error({
            message: "Error",
            description:
              error?.response?.data?.message || error?.response?.data?.error,
            duration: 3,
          });
          return reject(error);
        });
    });
  }

  setHeaders(data) {
    let headers = {};
    headers["accept-language"] = "en";
    headers["Content-Type"] = "application/json";
    headers["X-API-KEY"] = process.env.REACT_APP_X_API_KEY;

    if (data) {
      if (data.isMultipart) {
        headers["Content-Type"] = "multipart/form-data";
      }

      if (data.headers) {
        for (var key in data.headers) {
          if (data.headers.hasOwnProperty(key)) {
            headers[key] = data.headers[key];
          }
        }
      }
    }

    if (this.isLoggedInCMS !== false && !(data && data.skipAuth)) {
      headers["AccessToken"] = this.userData.accessToken;
    }

    return headers;
  }
}

// SAMPLE DATA JSON
/* let sample_data = {

    // ADDITIONAL HEADERS IF REQUIRED 
    headers :{
        'Content-type'  : 'xx-urlencode',
    },

    // IF USER ID AND TOKEN SHOULDN'T BE PASSED IN HEADERS (USER FOR AFTER LOGIN API)
    // DEFAULT : FALSE; 
    skipAuth    : false, 
    
    // IF Default error handling needs to be overridden
    // DEFAULT : FALSE; 
    skipErrorHandling    : false, 

    // FOR SENDING FILES OR FORM DATA REQUEST
    isMultipart : true, 

    // `PARAMS` ARE THE URL PARAMETERS TO BE SENT WITH THE REQUEST
    params : {
        user_id     : 10,
        name        : "lorem",
        page        : 3,
        sort_by     : 'name'
    },

    // POST DATA
    data : {
        firstName   : 'Lorem',
        lastName    : 'Ipsum'
    },
} */

export default Api;
