import { COLORS } from "Styles/Constants";
import styled from "styled-components";

export const ImageMsgWrapper = styled.div`
  padding: 5px;
  max-width: 60%;
  width: fit-content;

  &.admin-msg {
    border-radius: 10px 0 10px 10px;
    background: ${COLORS.PRIMARY};
    color: #ffffff;
    margin-left: auto;
  }

  &.user-msg {
    border-radius: 0 10px 10px 10px;
    border: 1px solid ${COLORS.PRIMARY};
    color: ${COLORS.PRIMARY};
  }

  .img-wrapper {
    position: relative;
    /* border: 1px solid red; */
    border-radius: 10px;

    .img {
      object-fit: cover;
      /* margin: 0 auto; */
    }

    .download-url {
      display: none;
    }

    .download-icon {
      font-size: 25px;
      border: 1px solid;
      border-radius: 50%;
      padding: 3px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

      &.icon-position {
        position: absolute;
        bottom: 5px;
        right: 5px;
      }
    }
  }

  .msg-text {
    &.admin {
      color: ${COLORS.WHITE};
    }
  }
`;
