//CORE
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Spin, Table, Tooltip, notification } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import * as XLSX from "xlsx";

//WRAPPER
import {
  LayoutContentStyle,
  LayoutContentWrapper,
  PageHeader,
} from "Components/Common/Common.style";

//API
import Api from "Helpers/ApiHandler";

//ICONS
import { EditOutlined, EyeOutlined } from "@ant-design/icons";

//CUSTOM
import {
  MODAL_TYPE,
  PAGINATION,
  USER_ACCOUNT_PENDING_VALUE,
  USER_SHEET_NAME,
  USER_TYPE,
} from "Helpers/Constants";
import {
  getFieldKeyValue,
  sortNumber,
  getColumnSearchProps,
  createMobileNo,
  handleInvestmentAmount,
} from "Helpers/Utils";
import { API_URL, URL_TRANSACTION_LOG } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import AddEditUserModal from "../Modal/AddEditUserModal";
import ViewUserManagement from "../Drawer/ViewUserManagement";
import { VerifiedUserWrapper } from "./VerifiedUser.style";

const VerifiedUser = () => {
  const API = useMemo(() => new Api(), []);
  const fileUploadRef = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    perPage: PAGINATION.PER_PAGE,
    filter: {
      field: "userId",
      order: "ASC",
    },
    search: {
      searchField: "",
      searchBy: "",
    },
  });
  const [userData, setUserData] = useState({
    data: [],
    totalRecords: 0,
  });
  const [userModal, setUserModal] = useState({
    data: null,
    visible: false,
    type: MODAL_TYPE.EDIT,
  });
  const [userDrawer, setUserDrawer] = useState({
    data: null,
    visible: false,
  });
  const [resetData, setResetData] = useState(false);

  const getVerifiedUser = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await API.post(API_URL.GET_VERIFIED_USER, {
        data: {
          pageNumber: paginationInfo.currentPage,
          totalRecordPerPage: paginationInfo.perPage,
          orderBy: paginationInfo.filter.field,
          order: paginationInfo.filter.order,
          searchField: paginationInfo.search.searchField,
          searchBy: paginationInfo.search.searchBy,
          customerType: USER_TYPE.VERIFIED_USER,
        },
      });

      if (response.status === CODES.SUCCESS) {
        setIsLoading(false);
        setUserData({
          data: response?.data?.list,
          totalRecords: response.data.totalElements,
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [API, paginationInfo]);

  const handleOpenModal = (type = MODAL_TYPE.EDIT, data) => {
    setUserModal((prev) => {
      return { ...prev, visible: true, data, type };
    });
  };

  const handleCloseModal = (shouldUpdate = false) => {
    setUserModal((prev) => {
      return { ...prev, data: null, visible: false };
    });
    shouldUpdate && setResetData((prev) => !prev);
  };

  const handleOpenDrawer = (data) => {
    setUserDrawer((prev) => {
      return { ...prev, data, visible: true };
    });
  };

  const handleCloseDrawer = () => {
    setUserDrawer((prev) => {
      return { ...prev, data: null, visible: false };
    });
  };

  const tableFiltersUpdated = (pagination, filters, sorter) => {
    let sorterOrder = "ASC";
    if (sorter.field)
      switch (sorter.order) {
        case "ascend":
          sorterOrder = "ASC";
          break;
        case "descend":
          sorterOrder = "DESC";
          break;
        default:
          sorterOrder = "ASC";
          sorter.field = "userId";
          break;
      }

    const searchValues = getFieldKeyValue(filters);

    setPaginationInfo((prev) => {
      return {
        ...prev,
        currentPage: pagination.current ? pagination.current : 1,
        perPage: pagination?.pageSize || PAGINATION.PER_PAGE,
        filter: {
          field: sorter.field || "userId",
          order: sorterOrder || "Asc",
        },
        search: {
          searchField: searchValues.filterKey,
          searchBy: searchValues.filterValue,
        },
      };
    });
  };

  const verifiedUserListUI = () => {
    const paginationConfig = {
      pageSize: paginationInfo.perPage,
      total: userData.totalRecords,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} of ${total} records`,
      current: paginationInfo.currentPage,
      showSizeChanger: true,
    };

    const columns = [
      {
        title: "Sr.No",
        dataIndex: "id",
        key: "id",
        rowScope: "row",
        fixed: "left",
        width: 70,
        render: (text, record, index) => (
          <span>
            {(paginationInfo.currentPage - 1) * paginationInfo.perPage +
              index +
              1}
          </span>
        ),
      },
      {
        title: "User Name",
        dataIndex: "fullName",
        key: "fullName",
        width: 150,
        sorter: (a, b) => sortNumber(a, b, "fullName"),
        ...getColumnSearchProps("fullName", "Full Name"),
        render: (text, record, index) => (
          <span style={{ textTransform: "capitalize" }}>{text}</span>
        ),
      },
      {
        title: "User Type",
        dataIndex: "isUserCreatedByCmsUser",
        key: "isUserCreatedByCmsUser",
        width: 150,
        render: (text, record, index) => (
          <span>
            {text
              ? USER_ACCOUNT_PENDING_VALUE.EXISTING_USER
              : USER_ACCOUNT_PENDING_VALUE.NEW_USER}
          </span>
        ),
      },
      {
        title: "Date of Birth",
        dataIndex: "createdDate",
        key: "createdDate",
        width: 150,
        render: (text, record, index) => (
          <span>{moment(new Date(text)).format("DD/MM/yy")}</span>
        ),
      },
      {
        title: "Mobile No",
        dataIndex: "mobileNumber",
        key: "mobileNumber",
        width: 150,
        ...getColumnSearchProps("mobileNumber", "Mobile no"),
        render: (text, record, index) => {
          return (
            <span>
              {createMobileNo(record?.countryCode, record?.mobileNumber)}
            </span>
          );
        },
      },
      {
        title: "Email",
        dataIndex: "emailId",
        key: "emailId",
        width: 250,
        ...getColumnSearchProps("emailId", "Email"),
      },
      {
        title: "Address",
        dataIndex: "fullAddress",
        key: "fullAddress",
        width: 200,
      },
      {
        title: "Invested",
        dataIndex: "userInvestmentFundsList",
        key: "userInvestmentFundsList",
        width: 120,
        render: (text, record, index) => (
          <span>{handleInvestmentAmount(text)}</span>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        fixed: "right",
        width: 120,
        render: (text, record, index) => (
          <div className="action-button-container">
            <div className="item">
              <Tooltip placement="top" title="Edit">
                <Button
                  className="ant-btn-sm"
                  onClick={() => handleOpenModal(MODAL_TYPE.EDIT, record)}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            </div>
            <div className="item">
              <Tooltip placement="top" title="View">
                <Button
                  className="ant-btn-sm"
                  onClick={() => handleOpenDrawer(record)}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>
            </div>
            <div className="item">
              <Tooltip
                placement="top"
                title={`${
                  !record?.userInvestmentFundsList.length
                    ? "No Fund Available"
                    : "Transaction Log"
                }`}
              >
                <Button
                  className="ant-btn-sm"
                  disabled={!record?.userInvestmentFundsList.length}
                  onClick={() =>
                    navigate(URL_TRANSACTION_LOG, {
                      state: { userRecord: record },
                    })
                  }
                >
                  <i className="ion-ios-paper" />
                </Button>
              </Tooltip>
            </div>
          </div>
        ),
      },
    ];
    return (
      <Table
        dataSource={userData.data}
        columns={columns}
        rowKey={"userId"}
        style={{ width: "100%" }}
        loading={{ indicator: <Spin />, spinning: isLoading }}
        scroll={{ x: 700 }}
        onChange={tableFiltersUpdated}
        pagination={
          userData.totalRecords > PAGINATION.PER_PAGE ? paginationConfig : false
        }
      />
    );
  };

  const handleExportCSV = async () => {
    try {
      setIsLoading(true);
      //GET JSON DATA
      const response = await API.post(API_URL.GET_VERIFIED_USER, {
        data: {
          pageNumber: 1,
          totalRecordPerPage: userData.totalRecords,
          orderBy: "full_name",
          order: "asc",
          searchField: "",
          searchBy: "",
          customerType: USER_TYPE.VERIFIED_USER,
        },
      });
      if (response.status === CODES.SUCCESS) {
        const dataArray = [];
        response?.data?.list.forEach((record) => {
          record?.userInvestmentFundsList.forEach((fund) => {
            dataArray.push({
              // userId: record?.userId,
              fullName: record?.fullName,
              emailId: record?.emailId,
              investmentPlanName: fund?.investmentPlanName,
              currentTotalInvestedAmount: fund?.currentTotalInvestedAmount,
              currentValue: fund?.currentTotalReturnAmount,
              fundName: fund?.fundName,
            });
          });
        });
        // CONVERT JSON TO XLSX FILE
        const worksheet = XLSX.utils.json_to_sheet(dataArray);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          USER_SHEET_NAME.VERIFIED_USER
        );
        XLSX.writeFile(workbook, "DataSheet.xlsx");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleUploadCSV = (event) => {
    try {
      setIsLoading(true);
      let selectedFile = event.target?.files[0];
      if (selectedFile) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = async (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "buffer", cellDates: true });
          if (wb.SheetNames.includes(USER_SHEET_NAME.VERIFIED_USER)) {
            const workSheet = wb.Sheets[USER_SHEET_NAME.VERIFIED_USER];
            const data = XLSX.utils.sheet_to_json(workSheet).map((item) => ({
              ...item,
              currentTotalReturnAmount: item?.currentValue,
            }));
            const response = await API.post(API_URL.CSV.VERIFIED_USER, {
              data: {
                investmentDetails: data,
              },
            });
            if (response.status === CODES.SUCCESS)
              notification.success({
                message: "Success",
                description: response?.message,
              });
            setResetData((prev) => !prev);
          } else {
            notification.warning({
              message: "Warning",
              description: `Selected file don't have a worksheet with name "${USER_SHEET_NAME.VERIFIED_USER}". Please add sheetName and then try again`,
            });
          }
        };
      } else {
        notification.warning({
          message: "Warning",
          description: `please select you file`,
        });
      }
      setIsLoading(false);
      fileUploadRef.current.value = null;
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getVerifiedUser();
  }, [getVerifiedUser, resetData]);

  return (
    <LayoutContentWrapper>
      <LayoutContentStyle>
        <VerifiedUserWrapper>
          <div className="title-container">
            <PageHeader>Verified User</PageHeader>
          </div>
          <div className="flex action-btn-section">
            <Button
              type="primary"
              onClick={() => fileUploadRef?.current?.click()}
            >
              Upload CSV
            </Button>
            <Button type="primary" onClick={handleExportCSV}>
              Export CSV
            </Button>
            <input
              ref={fileUploadRef}
              onChange={handleUploadCSV}
              type="file"
              accept=".xlsx, .xls, .csv"
              style={{ display: "none" }}
            />
          </div>
          <div className="table-container">{verifiedUserListUI()}</div>
          {userModal.visible && (
            <AddEditUserModal
              modalData={userModal}
              closeModal={handleCloseModal}
            />
          )}
          {userDrawer.visible && (
            <ViewUserManagement
              drawerData={userDrawer}
              closeDrawer={handleCloseDrawer}
            />
          )}
        </VerifiedUserWrapper>
      </LayoutContentStyle>
    </LayoutContentWrapper>
  );
};

export default VerifiedUser;
