import { COLORS } from "Styles/Constants";
import styled from "styled-components";

export const PageNotFoundStyle = styled.div`
  width: 100%;
  min-height: calc(100vh - 68px - 72px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .iso404Content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    @media only screen and (max-width: 767px) {
      order: 2;
      margin-top: 20px;
      margin-bottom: 20px;
      align-items: center;
      text-align: center;
      flex-direction: column;
    }

    h1 {
      font-size: 84px;
      font-weight: 700;
      color: ${COLORS.BLUE_LIGHT};
      line-height: 1;
      margin: 0 0 25px;
    }

    h3 {
      font-size: 24px;
      font-weight: 400;
      color: ${COLORS.HEADING};
      margin: 0 0 10px;
      line-height: 1.2;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: ${COLORS.TEXT_DEFAULT};
    }
  }

  .iso404Artwork {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
    margin-right: inherit;
    height: 400px;

    @media only screen and (max-width: 767px) {
      order: 1;
      margin-left: 0;
      margin-right: inherit;
      height: 300px;
    }

    img {
      max-height: 100%;
    }
  }
`;
