import { COLORS } from "Styles/Constants";
import styled from "styled-components";

export const WithdrawalRequestWrapper = styled.div`
  .segmented {
    margin-bottom: 15px;
  }

  .success-btn {
    background: ${COLORS.SUCCESS};
    border: 1px solid ${COLORS.SUCCESS} !important;

    &:hover {
      background: ${COLORS.SUCCESS_DARK};
    }

    .success-icon {
      color: #fff;
    }

    &:disabled {
      border: 1px solid #d9d9d9 !important;
      background: #0000000a;

      .success-icon {
        color: #00000040;
      }
    }
  }
`;
