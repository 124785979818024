import { DASHBOARD_KEY } from "./Constants";
import {
  URL_EMAIL_TEMPLATE,
  URL_USER_NOTIFICATION,
  URL_REGISTERED_USER,
  URL_VERIFIED_USER,
  URL_SUBSCRIPTION_PLAN,
  URL_STATIC_PAGE,
  URL_WITHDRAWAL_REQUEST,
  URL_SOCIAL_MEDIA,
  URL_NOTIFICATION,
  URL_CHAT,
  URL_FEEDBACK,
} from "./Paths";

const getItem = (label, key, icon, children, type) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

const MENU_ITEM = [
  // getItem(label, key, icon, [getItem(label, key, icon)](children))
  //1
  getItem("Dashboard", DASHBOARD_KEY, <i className="ion-speedometer" />),
  //2
  getItem(
    "User Management",
    "user-management",
    <i className="ion-android-people" />,
    [
      getItem(
        "Registered User",
        URL_REGISTERED_USER,
        <i className="ion-information-circled" />
      ),
      getItem(
        "Verified User",
        URL_VERIFIED_USER,
        <i className="ion-android-checkmark-circle" />
      ),
    ]
  ),
  //3
  getItem(
    "WithDraw Request",
    URL_WITHDRAWAL_REQUEST,
    <i className="ion-shuffle" />
  ),
  //4
  getItem(
    "Social Media",
    URL_SOCIAL_MEDIA,
    <i className="ion-android-phone-portrait" />
  ),
  //5
  getItem("Chat", URL_CHAT, <i className="ion-android-chat" />),
  //6
  getItem(
    "User Notification",
    URL_USER_NOTIFICATION,
    <i className="ion-ios-bell" />
  ),
  //7
  getItem(
    "Subscription",
    URL_SUBSCRIPTION_PLAN,
    <i className="ion-ribbon-b" />
  ),
  //8
  getItem("Template", "template", <i className="ion-document-text" />, [
    getItem("Email", URL_EMAIL_TEMPLATE, <i className="ion-email" />),
    getItem(
      "Notification",
      URL_NOTIFICATION,
      <i className="ion-android-notifications" />
    ),
  ]),
  //9
  getItem("Static Page", URL_STATIC_PAGE, <i className="ion-ios-paper" />),
  //10
  getItem("Feedback", URL_FEEDBACK, <i className="ion-chatbubbles" />)
  // getItem("Feedback", URL_FEEDBACK, <i className="ion-chatbubble-working" />)
];

export default MENU_ITEM;
