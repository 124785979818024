//CORE
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Modal, Select, Spin, notification } from "antd";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import { FormWrapper } from "Components/Common/Common.style";
import { FORM_PARAMS } from "Helpers/Constants";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";

const FEEDBACK_STATUS_OPTIONS = [
  { value: 1, label: "Open" },
  { value: 2, label: "Hold" },
  { value: 3, label: "Resolved" },
];

const FeedbackModal = (props) => {
  const { modalData } = props;
  const API = useMemo(() => new Api(), []);
  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await API.put(API_URL.FEEDBACK.UPDATE_STATUS, {
        data: {
          ...values,
          feedbackId: modalData?.id,
        },
      });
      if (response.status === CODES.SUCCESS) {
        handleClose(true);
        notification.success({
          message: "Success",
          description: response?.message,
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
      setIsLoading(false);
    }
  };

  const handleClose = (updateFlag = false) => {
    props.closeModal(updateFlag);
  };

  useEffect(() => {
    formRef.current.setFieldsValue({
      feedbackStatusId: modalData.feedbackStatusId,
    });
  }, [modalData.feedbackStatusId]);

  return (
    <Modal
      open
      title={"Edit Feedback"}
      okButtonProps={{ disabled: isLoading }}
      onCancel={() => handleClose()}
      footer={[
        <Button key="back" type="default" onClick={() => handleClose()}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => formRef.current?.submit()}
        >
          Update
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <FormWrapper
          ref={formRef}
          onFinish={handleSubmit}
          scrollToFirstError
          labelAlign="left"
          labelCol={{ span: FORM_PARAMS.LABEL_COL_SPAN }}
          labelWrap
          initialValues={{
            name: modalData?.name,
            url: modalData?.url,
          }}
        >
          <Form.Item
            name="feedbackStatusId"
            key="feedbackStatusId"
            label="Feedback Status"
          >
            <Select
              optionFilterProp="label"
              options={FEEDBACK_STATUS_OPTIONS}
              placeholder="Select your Status"
            />
          </Form.Item>
        </FormWrapper>
      </Spin>
    </Modal>
  );
};

export default FeedbackModal;
