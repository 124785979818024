import styled from "styled-components";

export const DashboardWrapper = styled.div`
  width: 100%;

  .grid-row {
    width: 100%;

    .grid-col {
      margin-bottom: 15px;

      .chart-card{
        margin: 0 5px;
      }

      .responsive-chart-container{
        padding: 5px;
      }
    }
  }
`;
