import { css } from "styled-components";

// COLOR PALLET
export const COLORS = {
  //PRIMARY
  PRIMARY: "#5F85CB",
  // PRIMARY: "#1E1D57",
  PRIMARY_DARK: "#202739",
  PRIMARY_LIGHT: "#97b2df",
  // PRIMARY_LIGHT: "#4c4a79",
  PRIMARY_FADE_20: "#00449533",
  PRIMARY_FADE_75: "##004495bf",

  //BLUE
  BLUE_LIGHT: "#788195",
  BLUE_DARK: "#E4E6E9",

  //WARNING
  WARNING: "#ffbf00",

  //SUCCESS
  SUCCESS: "#00b16a",
  SUCCESS_DARK: "#059d60",

  //ERROR
  ERROR: "#df312e",
  ERROR_LIGHT: "#EC3D3A",
  ERROR_DARK: "#c52c2a",

  //GREY
  GREY: "#ededed",
  GREY_DARK: "#c1c1c1",
  GREY_SHADE: "#bababa",
  GREY_LIGHT: "F3F3F3",

  //WHITE
  DARKER_WHITE: "#F9F9F9",
  WHITE_GREY: "#fbfbfb",
  BLUEISH_WHITE: "#f5f6f8",
  DARK_WHITE: "#fafafa",

  LIGHT_BLUEISH: "#f1f3f6",

  //BORDER
  BORDER: "#e9e9e9",
  BORDER_DARK: "#d8d8d8",
  BORDER_LIGHT: "#ebebeb",

  //TEXT
  HEADING: "#323332",
  HEADING_LIGHT: "#595959",
  TEXT_DEFAULT: "#979797",
  TEXT_DARK: "#797979",
  TEXT_LIGHT: "#6a6c6a",

  //COLOR
  ORANGE: "#FEAC01",
  PURPLE: "#42299a",
  PURPLE_LIGHT: "#7266BA",
  PINK: "#F75D81",
  LIME_GREEN: "#7ED321",
  BLUE_SHADE: "#39435f",
  YELLOW: "#FFCA28",
  YELLOW_DARK: "#F2BD1B",
  SKY_BLUE: "#00bfff",
  
  //BLACK
  BLACK: "#31290E",
  WHITE: "#FFF",
};

export const DIMENSIONS = {
  TOP_BAR: 70,
  FOOTER: 65,
};

// BREAKPOINTS
export const BREAKPOINTS_VALUE = {
  DISPLAY: 1750,
  DISPLAY_1650: 1650,
  DISPLAY_1600: 1600,
  DISPLAY_1550: 1550,
  DESKTOP: 1450, // x_large
  LAPTOP: 1278, // large
  LAPTOP_1200: 1200,
  LAPTOP_1100: 1100,
  LAPTOP_1050: 1050,
  TABLET: 960, // medium
  TABLET_900: 900,
  TABLET_860: 860,
  TABLET_800: 800,
  TABLET_725: 725,
  TABLET_700: 700,
  PHABLET: 600, // small
  PHABLET_500: 500,
  MOBILE: 450, // x_small
  MOBILE_376: 376, // x_small
  MOBILE_340: 340, // x_small
  MOBILE_325: 325, // x_small
};

export const BREAKPOINTS = {
  A_DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP}px`,
  A_LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP}px`,
  A_LAPTOP_1200: `${BREAKPOINTS_VALUE.LAPTOP_1200}px`,
  A_LAPTOP_1100: `${BREAKPOINTS_VALUE.LAPTOP_1100}px`,
  A_LAPTOP_1050: `${BREAKPOINTS_VALUE.LAPTOP_1050}px`,
  A_TABLET: `${BREAKPOINTS_VALUE.TABLET}px`,
  A_TABLET_860: `${BREAKPOINTS_VALUE.TABLET_860}px`,
  A_TABLET_800: `${BREAKPOINTS_VALUE.TABLET_800}px`,
  A_TABLET_725: `${BREAKPOINTS_VALUE.TABLET_725}px`,
  A_TABLET_700: `${BREAKPOINTS_VALUE.TABLET_700}px`,
  A_PHABLET: `${BREAKPOINTS_VALUE.PHABLET}px`,
  A_PHABLET_500: `${BREAKPOINTS_VALUE.PHABLET_500}px`,
  A_MOBILE: `${BREAKPOINTS_VALUE.MOBILE}px`,
  A_MOBILE_376: `${BREAKPOINTS_VALUE.MOBILE_376}px`,
  A_MOBILE_325: `${BREAKPOINTS_VALUE.MOBILE_325}px`,

  DISPLAY: `${BREAKPOINTS_VALUE.DISPLAY - 1}px`,
  DISPLAY_1650: `${BREAKPOINTS_VALUE.DISPLAY_1650 - 1}px`,
  DISPLAY_1600: `${BREAKPOINTS_VALUE.DISPLAY_1600 - 1}px`,
  DISPLAY_1550: `${BREAKPOINTS_VALUE.DISPLAY_1550 - 1}px`,
  DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP - 1}px`, // x_large
  LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP - 1}px`, // large
  LAPTOP_1200: `${BREAKPOINTS_VALUE.LAPTOP_1200 - 1}px`, // large
  LAPTOP_1100: `${BREAKPOINTS_VALUE.LAPTOP_1100 - 1}px`, // large
  LAPTOP_1050: `${BREAKPOINTS_VALUE.LAPTOP_1050 - 1}px`, // large
  TABLET: `${BREAKPOINTS_VALUE.TABLET - 1}px`, // medium
  TABLET_860: `${BREAKPOINTS_VALUE.TABLET_860 - 1}px`, // medium
  TABLET_800: `${BREAKPOINTS_VALUE.TABLET_800 - 1}px`, // medium
  TABLET_725: `${BREAKPOINTS_VALUE.TABLET_725 - 1}px`, // medium
  TABLET_700: `${BREAKPOINTS_VALUE.TABLET_700 - 1}px`, // medium
  PHABLET: `${BREAKPOINTS_VALUE.PHABLET - 1}px`, // small
  PHABLET_500: `${BREAKPOINTS_VALUE.PHABLET_500 - 1}px`, // small
  MOBILE: `${BREAKPOINTS_VALUE.MOBILE - 1}px`, // x_small
  MOBILE_376: `${BREAKPOINTS_VALUE.MOBILE_376 - 1}px`, // x_small
  MOBILE_340: `${BREAKPOINTS_VALUE.MOBILE_340 - 1}px`, // x_small
  MOBILE_325: `${BREAKPOINTS_VALUE.MOBILE_325 - 1}px`, // x_small
};

//Fonts

export const FONTS = {};

export const BASE_FONT_SIZE = 13;

export const responsive = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${BREAKPOINTS[label]}) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
