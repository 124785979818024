//CORE
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Spin, Table, Tooltip, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

//WRAPPER
import {
  LayoutContentStyle,
  LayoutContentWrapper,
  PageHeader,
} from "Components/Common/Common.style";

//ICONS
import {
  EditOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  ArrowLeftOutlined,
  EyeOutlined,
} from "@ant-design/icons";

//CUSTOM
import { PAGINATION } from "Helpers/Constants";
import EditTransactionLog from "./Modal/EditTransactionLog";
import { COLORS } from "Styles/Constants";
import { UKCurrencyConverter, handleInvestmentAmount } from "Helpers/Utils";
import { TransactionLogWrapper } from "./TransactionLog.style";
import { Content } from "antd/es/layout/layout";
import { API_URL } from "Helpers/Paths";
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import ViewTransactionLog from "./Drawer/ViewTransactionLog";

const TransactionLog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userRecord } = location?.state;
  const API = useMemo(() => new Api(), []);

  const [paginationInfo] = useState({
    currentPage: 1,
    perPage: PAGINATION.PER_PAGE,
  });
  const [transactionLogData, setTransactionLogData] = useState({
    data: [],
    totalRecords: 0,
  });
  const [TransactionLogModal, setTransactionLogModal] = useState({
    data: null,
    visible: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [resetData, setResetData] = useState(false);
  const [transactionLogDrawer, setTransactionLogDrawer] = useState({
    data: null,
    visible: false,
  });

  const getTransactionLog = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await API.post(API_URL.GET_INVESTMENT_LOG, {
        data: {
          userId: userRecord?.userId,
        },
      });
      if (response.status === CODES.SUCCESS) {
        setTransactionLogData({
          data: response?.data,
          totalRecords: response.data.length,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [API, userRecord?.userId]);

  const handleOpenModal = (data) => {
    setTransactionLogModal((prev) => {
      return { ...prev, visible: true, data };
    });
  };

  const handleCloseModal = (shouldUpdate = false, record) => {
    setTransactionLogModal((prev) => {
      return { ...prev, data: null, visible: false };
    });
    shouldUpdate && setResetData((prev) => !prev);
    // if (shouldUpdate) {
    //   const transactionLogIndex = transactionLogData?.data.findIndex(
    //     (fund) => fund.id === record?.id
    //   );
    //   const updatedTransactionLogData = transactionLogData?.data.map(
    //     (item, index) => (index === transactionLogIndex ? record : item)
    //   );
    //   setTransactionLogData((prev) => ({
    //     ...prev,
    //     data: updatedTransactionLogData,
    //   }));
    // }
  };

  const handleOpenDrawer = (data) => {
    setTransactionLogDrawer((prev) => {
      return { ...prev, data, visible: true };
    });
  };

  const handleCloseDrawer = () => {
    setTransactionLogDrawer((prev) => {
      return { ...prev, data: null, visible: false };
    });
  };

  const TransactionListUI = () => {
    const paginationConfig = {
      pageSize: paginationInfo.perPage,
      total: transactionLogData.totalRecords,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} of ${total} records`,
      current: paginationInfo.currentPage,
      showSizeChanger: true,
    };

    const columns = [
      {
        title: "Sr.No",
        dataIndex: "id",
        key: "id",
        rowScope: "row",
        fixed: "left",
        width: 70,
        render: (text, record, index) => (
          <span>
            {(paginationInfo.currentPage - 1) * paginationInfo.perPage +
              index +
              1}
          </span>
        ),
      },
      {
        title: "Plan Name",
        dataIndex: "investmentPlanName",
        key: "investmentPlanName",
        width: 120,
      },
      {
        title: "Fund Name",
        dataIndex: "fundName",
        key: "fundName",
        width: 150,
      },
      {
        title: "Invested Amount (£)",
        dataIndex: "currentTotalInvestedAmount",
        key: "currentTotalInvestedAmount",
        width: 150,
      },
      {
        title: "Current Value (£)",
        dataIndex: "currentTotalReturnAmount",
        key: "currentTotalReturnAmount",
        width: 140,
        render: (text, record, index) => (
          <span
            style={{
              color:
                text >= record?.currentTotalInvestedAmount
                  ? COLORS.SUCCESS
                  : COLORS.ERROR,
            }}
          >
            {text}{" "}
            {text >= record?.currentTotalInvestedAmount ? (
              <ArrowUpOutlined />
            ) : (
              <ArrowDownOutlined />
            )}
          </span>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        fixed: "right",
        width: 100,
        render: (text, record, index) => (
          <div className="action-button-container">
            <div className="item">
              <Tooltip placement="top" title="Edit">
                <Button
                  className="ant-btn-sm"
                  onClick={() => handleOpenModal(record)}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            </div>
            <div className="item">
              <Tooltip placement="top" title="View">
                <Button
                  className="ant-btn-sm"
                  onClick={() => handleOpenDrawer(record)}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>
            </div>
          </div>
        ),
      },
    ];

    return (
      <Table
        dataSource={transactionLogData.data}
        columns={columns}
        rowKey={"id"}
        style={{ width: "100%" }}
        loading={{ indicator: <Spin />, spinning: isLoading }}
        scroll={{ x: 700 }}
        // onChange={tableFiltersUpdated}
        pagination={
          transactionLogData.totalRecords > PAGINATION.PER_PAGE
            ? paginationConfig
            : false
        }
      />
    );
  };

  useEffect(() => {
    getTransactionLog();
  }, [getTransactionLog, resetData]);

  return (
    <LayoutContentWrapper>
      <LayoutContentStyle>
        <TransactionLogWrapper>
          <div className="title-container flex">
            <PageHeader>
              {" "}
              <ArrowLeftOutlined
                className="back-icon"
                onClick={() => navigate(-1)}
              />
              {userRecord.fullName} Transaction Log
            </PageHeader>
          </div>
          <div className="flex transaction-log-details">
            {/* <div className="total-invested-section">
              <Typography className="text total-invested-amount">
                {UKCurrencyConverter.format(
                  handleInvestmentAmount(
                    transactionLogData.data,
                    "currentTotalReturnAmount"
                  )
                )}
              </Typography>
              <Typography className="text">Current Value</Typography>
            </div>
            <Divider type="vertical" className="divider" /> */}
            <div className="investment-details">
              <Content className="fields flex">
                <Typography className="key text">Invested Amount</Typography>
                <Typography className="separator text">:</Typography>
                <Typography className="value text">
                  {UKCurrencyConverter.format(
                    handleInvestmentAmount(transactionLogData.data)
                  )}
                </Typography>
              </Content>
              <Content className="fields flex">
                <Typography className="key text">Total Returns</Typography>
                <Typography className="separator text">:</Typography>
                <Typography
                  className={`value ${
                    handleInvestmentAmount(
                      transactionLogData.data,
                      "currentTotalReturnAmount"
                    ) >= handleInvestmentAmount(transactionLogData.data)
                      ? "green"
                      : "red"
                  }`}
                >
                  {/* {UKCurrencyConverter.format(
                    handleInvestmentAmount(
                      transactionLogData.data,
                      "currentTotalReturnAmount"
                    ) - handleInvestmentAmount(transactionLogData.data)
                  )} */}
                  {UKCurrencyConverter.format(
                    handleInvestmentAmount(
                      transactionLogData.data,
                      "currentTotalReturnAmount"
                    )
                  )}
                </Typography>
              </Content>
            </div>
          </div>
          <div className="table-container">{TransactionListUI()}</div>
          {TransactionLogModal.visible && (
            <EditTransactionLog
              modalData={TransactionLogModal}
              closeModal={handleCloseModal}
            />
          )}
          {transactionLogDrawer.visible && (
            <ViewTransactionLog
              drawerData={transactionLogDrawer}
              closeDrawer={handleCloseDrawer}
            />
          )}
        </TransactionLogWrapper>
      </LayoutContentStyle>
    </LayoutContentWrapper>
  );
};

export default TransactionLog;
