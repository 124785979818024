import { COLORS } from "Styles/Constants";
import styled from "styled-components";

export const FilePreviewWrapper = styled.div`
  background: ${COLORS.GREY_SHADE};
  padding: 10px;
  border-radius: 10px;

  .file-prev {
    gap: 10px;
    width: 100%;

    .pdf-icon {
      font-size: 20px;
    }

    .filename {
      max-width: 85%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .trash-icon {
    /* color: ${COLORS.GREY_SHADE}; */
    font-size: ${(props) => (props?.$largeIcon ? "25px" : "auto")};
  }
`;
