import React, { useRef } from "react";
import { CHAT_MSG_SEND_FROM } from "Helpers/Constants";
import { Typography } from "antd";
import { MdFileDownload } from "react-icons/md";
import { ImageMsgWrapper } from "./ImageMessage.style";

const ImageMessage = ({ item }) => {
  const downloadUrlRef = useRef(null);

  return (
    <ImageMsgWrapper
      className={`file-msg ${
        item?.sendFrom === CHAT_MSG_SEND_FROM.ADMIN ? "admin-msg" : "user-msg"
      }`}
    >
      <div className="img-wrapper flex f-h-center">
        {/* <Image
          height={80}
          width={100}
          src={item?.attachmentFileURL}
          alt={item?.fileName}
        /> */}
        <img
          height={100}
          width={200}
          src={item?.attachmentFileURL}
          alt={item?.fileName}
          className="img"
        />
        <MdFileDownload
          className="download-icon hover icon-position"
          onClick={() => downloadUrlRef?.current?.click()}
        />
        <a
          href={item?.attachmentFileURL}
          ref={downloadUrlRef}
          className="download-url"
        >
          click me
        </a>
      </div>
      <Typography
        className={`msg-text ${
          item?.sendFrom === CHAT_MSG_SEND_FROM.ADMIN ? "admin" : "user"
        }`}
      >
        {item?.message}
      </Typography>
    </ImageMsgWrapper>
  );
};

export default ImageMessage;
