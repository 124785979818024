//CORE
import React, { useMemo, useRef, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Spin,
  Tag,
  Typography,
  notification,
} from "antd";
import EmailEditor from "react-email-editor";
import { Content } from "antd/es/layout/layout";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import { FORM_PARAMS } from "Helpers/Constants";
import { EmailModalWrapper } from "./EmailTemplateModal.style";
import CODES from "Helpers/StatusCodes";
import { API_URL } from "Helpers/Paths";
import { FormWrapper } from "Components/Common/Common.style";

const EmailTemplateModal = (props) => {
  const { modalData } = props;
  const formRef = useRef(null);
  const emailEditorRef = useRef(null);
  const API = useMemo(() => new Api(), []);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      let dataToSend = {
        id: modalData?.id,
        title: values.title,
        body: null,
        json: null,
      };

      emailEditorRef.current?.editor?.exportHtml(async (data) => {
        const { design, html } = data;
        dataToSend.json = JSON.stringify(design);
        dataToSend.body = html;

        const response = await API.put(API_URL.EDIT_EMAIL_TEMPLATE, {
          data: dataToSend,
        });

        if (response.status === CODES.SUCCESS) {
          notification.success({
            message: "Success",
            description: response?.message,
          });
          handleClose(true);
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onEditorLoad = () => {
    if (modalData?.json) {
      const emailTemplateJson = modalData?.json;
      if (!emailEditorRef?.current)
        setTimeout(
          () =>
            emailEditorRef?.current?.editor.loadDesign(
              JSON?.parse(emailTemplateJson)
            ),
          3000
        );
      else {
        emailEditorRef?.current?.editor.loadDesign(
          JSON?.parse(emailTemplateJson)
        );
      }
    }
  };

  const handleTemplateVar = (type) => {
    switch (type) {
      case "FORGOT_PASSWORD_CMS":
        return (
          <Content className="var-wrapper">
            <Typography className="type-text">Name : $name</Typography>
            <Typography className="type-text">
              Reset Url : $verificationUrl
            </Typography>
          </Content>
        );
      case "SEND_SIGNUP_OTP_TO_EMAIL":
        return (
          <Content className="var-wrapper">
            <Typography className="type-text">otp : $otp</Typography>
          </Content>
        );
      case "FORGOT_PASSWORD_APP":
        return (
          <Content className="var-wrapper">
            <Typography className="type-text">otp : $otp</Typography>
          </Content>
        );
      case "WELCOME_EMAIL":
        return (
          <Content className="var-wrapper">
            <Typography className="type-text">user name : $name</Typography>
          </Content>
        );
      case "SEND_CREDENTIAL_ON_USER_CREATION":
        return (
          <Content className="var-wrapper">
            <Typography className="type-text">client name : $name</Typography>
            <Typography className="type-text">user name : $userName</Typography>
            <Typography className="type-text">
              user password : $password
            </Typography>
          </Content>
        );
      default:
        return;
    }
  };

  const handleClose = (updateFlag = false) => {
    props.closeModal(updateFlag);
  };

  return (
    <Modal
      open
      centered
      title="Edit template"
      width="70%"
      okButtonProps={{ disabled: isLoading }}
      onCancel={() => handleClose()}
      footer={[
        <Button key="back" type="default" onClick={() => handleClose()}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => formRef.current?.submit()}
        >
          Update
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <Tag color={"red"}>
          {`Note: Please do not change any word which is followed by $`}
        </Tag>
        <EmailModalWrapper>
          <FormWrapper
            ref={formRef}
            onFinish={handleSubmit}
            scrollToFirstError
            labelAlign="left"
            className="form"
            labelCol={{ span: FORM_PARAMS.LABEL_COL_SPAN }}
            labelWrap
            initialValues={{
              emailType: modalData.emailType,
              title: modalData.title,
            }}
          >
            <Form.Item key="variableName" label="Variable Names">
              {handleTemplateVar(modalData.emailType)}
            </Form.Item>
            <Form.Item
              name="emailType"
              key="emailType"
              label="Email Type"
              rules={[
                {
                  required: true,
                  message: "Please enter email type",
                },
              ]}
            >
              <Input placeholder="Enter email type" disabled={true} />
            </Form.Item>
            <Form.Item
              name="title"
              key="title"
              label="Subject"
              rules={[
                {
                  required: true,
                  message: "Please enter email subject",
                },
              ]}
            >
              <Input placeholder="Enter email subject" />
            </Form.Item>
            <Form.Item
              name="emailTemplate"
              className="email-editor-item"
              key="emailTemplate"
            >
              <EmailEditor
                className="email-editor"
                ref={emailEditorRef}
                onReady={onEditorLoad}
              />
            </Form.Item>
          </FormWrapper>
        </EmailModalWrapper>
      </Spin>
    </Modal>
  );
};

export default EmailTemplateModal;
