//CORE
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";

//STYLE
import 'react-quill/dist/quill.snow.css';

//CUSTOM
import Website from "Components/Website";
import { store } from "Redux/Store";
import { UtilityStyles } from "Styles/Utils";
import { AntComponentTheme } from "themeSetting";

const App = () => {
  return (
    <ConfigProvider theme={AntComponentTheme}>
      <Provider store={store}>
        <BrowserRouter>
          <Website />
          <UtilityStyles />
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
