//CORE
import React, { useMemo, useRef, useState } from "react";
import { Button, Form, Input, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

//ICONS
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import { LoginWrapper } from "./Login.style";
import { API_URL, URL_DASHBOARD, URL_FORGOT_PASSWORD } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { loginUser } from "Redux/Auth/Actions";
import { changeCurrent } from "Redux/App/Actions";
import { DASHBOARD_KEY, REGEX } from "Helpers/Constants";

const Login = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const API = useMemo(() => new Api(), []);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await API.post(API_URL.LOGIN, {
        data: {
          emailId: values.email,
          password: values.password,
        },
      });
      if (response.status === CODES.SUCCESS) {
        setIsLoading(false);
        notification.success({
          message: "Success",
          description: response?.message,
        });
        dispatch(loginUser(response?.data));
        navigate({ pathname: URL_DASHBOARD });
        dispatch(changeCurrent([DASHBOARD_KEY]));
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <LoginWrapper className="flex f-v-center f-h-center">
      <div className="login-form-wrapper">
        <div className="logo"></div>
        <Form
          ref={formRef}
          onFinish={handleSubmit}
          scrollToFirstError
          layout="vertical"
          className="login-form"
        >
          <Form.Item
            name="email"
            label="Email"
            className="form-item"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter valid email.",
              },
            ]}
          >
            <Input
              className="input-field"
              autoFocus
              size="large"
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            className="form-item pass-item"
            required
            rules={[
              {
                validator: (_, values) => {
                  if (REGEX.PASSWORD.test(values)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      "Password must have at least 8 characters, one uppercase, one lowercase, one special character and one numeric character."
                    );
                  }
                },
              },
            ]}
          >
            <Input.Password
              size="large"
              className="input-field"
              placeholder="Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <p className="forgot-password-text">
            <Link to={URL_FORGOT_PASSWORD}>Forgot Password?</Link>
          </p>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="sign-in-btn"
          >
            Login
          </Button>
        </Form>
      </div>
    </LoginWrapper>
  );
};

export default Login;
