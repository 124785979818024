//CORE
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Spin,
  notification,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";

//ICONS
// import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

//CUSTOM
import { FORM_PARAMS, MODAL_TYPE, REGEX } from "Helpers/Constants";
import Api from "Helpers/ApiHandler";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { FormWrapper } from "Components/Common/Common.style";
import { disabledFutureDate } from "Helpers/Utils";

const AddEditUserModal = (props) => {
  const { modalData } = props;
  const API = useMemo(() => new Api(), []);
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const data = {
        fullName: values.fullName,
        dob: moment(values.dob.$d).format("YYYY-MM-DD"),
        countryCode: values.countryCode,
        mobileNumber: values.mobileNumber,
        emailId: values.emailId,
        // password: values.password,
        address: {
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          postCode: values.postCode,
          town: values.town,
        },
      };

      let response;

      if (modalData.type === MODAL_TYPE.ADD) {
        response = await API.post(API_URL.ADD_USER, {
          data,
        });
      } else {
        data["id"] = modalData.data.userId;
        response = await API.patch(API_URL.EDIT_USER, {
          data,
        });
      }
      if (response.status === CODES.SUCCESS) {
        notification.success({
          message: "Success",
          description: response?.message,
        });
        setIsLoading(false);
        handleClose(true);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = (updateFlag = false) => {
    props.closeModal(updateFlag);
  };

  useEffect(() => {
    if (modalData.type === MODAL_TYPE.EDIT) {
      formRef.current.setFieldValue("fullName", modalData?.data?.fullName);
      formRef.current.setFieldValue(
        "dob",
        dayjs(modalData?.data?.dob, "YYYY-MM-DD")
      );
      formRef.current.setFieldValue(
        "countryCode",
        modalData?.data?.countryCode
      );
      formRef.current.setFieldValue(
        "mobileNumber",
        modalData?.data?.mobileNumber
      );
      formRef.current.setFieldValue("emailId", modalData?.data?.emailId);
      formRef.current.setFieldValue(
        "addressLine1",
        modalData?.data?.customerAddress?.addressLine1
      );
      formRef.current.setFieldValue(
        "addressLine2",
        modalData?.data?.customerAddress?.addressLine2
      );
      formRef.current.setFieldValue(
        "postCode",
        modalData?.data?.customerAddress?.postCode
      );
      formRef.current.setFieldValue(
        "town",
        modalData?.data?.customerAddress?.town
      );
    }
  }, [modalData]);

  return (
    <Modal
      open
      title={`${modalData?.type === MODAL_TYPE.ADD ? "Add" : "Edit"} User`}
      okButtonProps={{ disabled: isLoading }}
      onCancel={() => handleClose()}
      footer={[
        <Button key="back" type="default" onClick={() => handleClose()}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => formRef.current?.submit()}
        >
          {modalData?.type === MODAL_TYPE.ADD ? "Add" : "Update"}
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <FormWrapper
          ref={formRef}
          onFinish={handleSubmit}
          scrollToFirstError
          labelAlign="left"
          labelCol={{ span: FORM_PARAMS.LABEL_COL_SPAN_XL }}
          labelWrap
          className="form-height"
        >
          <Form.Item
            name="fullName"
            key="fullName"
            label="Full Name"
            rules={[
              {
                required: true,
                message: "Please enter full name",
              },
            ]}
          >
            <Input placeholder="Enter full name" />
          </Form.Item>
          <Form.Item
            name="dob"
            key="dob"
            label="Date of Birth"
            rules={[
              {
                required: true,
                message: "Please enter date of birth",
              },
            ]}
          >
            <DatePicker
              disabledDate={disabledFutureDate}
              style={{ width: "100%" }}
              format="DD/MM/YYYY"
              placeholder="Enter date of birth"
            />
          </Form.Item>
          <Form.Item
            name="countryCode"
            key="countryCode"
            label="Country Code"
            rules={[
              {
                required: true,
                message: "Please enter country code",
              },
            ]}
            onChange={(e) =>
              formRef.current?.setFieldValue(
                "countryCode",
                e?.target?.value.replace(REGEX.NUMBER, "")
              )
            }
          >
            <Input placeholder="Enter country code" />
          </Form.Item>
          <Form.Item
            name="mobileNumber"
            key="mobileNumber"
            label="Mobile no"
            rules={[
              {
                required: true,
                message: "Please enter mobile no",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value.length < 10) {
                    return Promise.reject(
                      new Error("Phone number must be 10 digits")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            onChange={(e) =>
              formRef.current?.setFieldValue(
                "mobileNumber",
                e?.target?.value.replace(REGEX.NUMBER, "")
              )
            }
          >
            <Input placeholder="Enter mobile no" maxLength={10} />
          </Form.Item>
          <Form.Item
            name="emailId"
            key="emailId"
            label="Email id"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter valid email id",
              },
            ]}
          >
            <Input
              placeholder="Enter email id"
              disabled={modalData.type === MODAL_TYPE.EDIT}
            />
          </Form.Item>
          {/* <Form.Item
            name="password"
            key="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter password",
              },
              {
                min: 6,
                message: "Password must be minimum 6 characters.",
              },
            ]}
          >
            <Input.Password
              placeholder="Enter password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item> */}
          <Form.Item
            name="addressLine1"
            key="addressLine1"
            label="Address Line 1"
            rules={[
              {
                required: true,
                message: "Please enter address line 1",
              },
            ]}
          >
            <Input placeholder="Enter address line 1" />
          </Form.Item>
          <Form.Item
            name="addressLine2"
            key="addressLine2"
            label="Address Line 2"
            rules={[
              {
                required: true,
                message: "Please enter address line 2",
              },
            ]}
          >
            <Input placeholder="Enter address line 2" />
          </Form.Item>
          <Form.Item
            name="postCode"
            key="postCode"
            label="Post Code"
            // onChange={(e) =>
            //   formRef.current?.setFieldValue(
            //     "postCode",
            //     e?.target?.value.replace(REGEX.NUMBER, "")
            //   )
            // }
            rules={[
              {
                required: true,
                message: "Please enter post code",
              },
            ]}
          >
            <Input placeholder="Enter post code" />
          </Form.Item>
          <Form.Item
            name="town"
            key="town"
            label="Town"
            rules={[
              {
                required: true,
                message: "Please enter town name",
              },
            ]}
          >
            <Input placeholder="Enter town name" />
          </Form.Item>
        </FormWrapper>
      </Spin>
    </Modal>
  );
};

export default AddEditUserModal;
