import { COLORS } from "Styles/Constants";
import { Form } from "antd";
import styled from "styled-components";

export const LayoutContentWrapper = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    padding: 50px 20px;
  }

  @media only screen and (max-width: 580px) {
    padding: 15px;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  .admin-table-buttons-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .admin-table-buttons {
    margin-left: auto;
  }

  .action-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .item {
      margin-left: 5px;
    }
  }
`;

export const LayoutContentStyle = styled.div`
  width: 100%;
  padding: 35px;
  background-color: #ffffff;
  border: 1px solid ${COLORS.BORDER};
  height: 100%;
`;

export const PageHeader = styled.h1`
  font-size: 19px;
  color: ${COLORS.BLUE_LIGHT};
  width: 100%;
  margin-right: 17px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media only screen and (max-width: 767px) {
    margin: 0 10px;
    margin-bottom: 30px;
  }

  &:before {
    content: "";
    width: 5px;
    height: 40px;
    background-color: ${COLORS.BLUE_DARK};
    display: flex;
    margin: 0 15px 0 0;
  }

  &:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: ${COLORS.BLUE_DARK};
    display: flex;
    margin: 0 0 0 15px;
  }
`;

export const WidgetWrapper = styled.div`
  margin: 0 10px;

  @media only screen and (max-width: 767) {
    margin-right: 0 !important;
  }
`;

export const FormWrapper = styled(Form)`
  .ant-form-item-required {
    &::before {
      position: absolute;
      right: -5px;
      /* margin: 0; */
    }
  }

  &.form{
    max-height: 55vh;
    overflow: auto;
  }
`;

export const DrawerWrapper = styled.div`
  .card {
    display: flex;
    margin: 0 0 10px 0;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 15%);

    .title {
      font-size: 20px;
      font-weight: 600;
      text-decoration: underline;
      color: ${COLORS.PRIMARY};
      margin-bottom: 5px;
    }

    .img-content {
      .sub-title {
        text-align: center;
        font-weight: 600;
        text-transform: capitalize;
        margin: 5px 0;
      }
    }

    .fields {
      display: flex;

      .key {
        font-size: 16px;
        font-weight: 800;
        min-width: ${(props) => props?.$keyWidth + "px" || `100px`};
      }

      .separator {
        margin: 0 5px;
      }

      .value {
        font-size: 16px;
      }
    }

    .avatar-fields {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin-bottom: 15px;
    }
  }
`;

export const PageContentWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
  padding: 0;

  img{
    display: none;
  }
`;