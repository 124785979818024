import { COLORS, responsive } from "Styles/Constants";
import styled from "styled-components";

export const ChatWrapper = styled.div`
  .select-user-type {
    width: 150px;
  }

  .user-list-section {
    max-height: calc(100vh - 70px - 65px - 80px - 75px - 80px);
    overflow-y: auto;

    .card {
      cursor: pointer;

      &:hover{
        background: ${COLORS.GREY};
      }
  
      .user-content {
        gap: 10px;

        .avatar-text{
          text-transform: uppercase;
        }
  
        .user-details {
          max-width: 90%;
  
          ${responsive.MOBILE`
              max-width: 85%;
          `}
  
          .msg {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    .no-data {
      gap: 10px;
    }
  }


`;
