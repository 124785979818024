import { COLORS } from "Styles/Constants";
import styled from "styled-components";

export const PdfMsgWrapper = styled.div`
  padding: 5px;
  max-width: 60%;
  width: fit-content;

  &.admin-msg {
    border-radius: 10px 0 10px 10px;
    background: ${COLORS.PRIMARY};
    color: #ffffff;
    margin-left: auto;
  }

  &.user-msg {
    border-radius: 0 10px 10px 10px;
    border: 1px solid ${COLORS.PRIMARY};
    color: ${COLORS.PRIMARY};
  }

  .pdf-wrapper {
    gap: 5px;
    margin-bottom: 5px;

    .pdf-icon-size {
      font-size: 25px;
    }

    .pdf-file-name {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &.admin {
        color: ${COLORS.WHITE};
      }
    }

    .download-icon {
      font-size: 25px;
      border: 1px solid;
      border-radius: 50%;
      padding: 3px;
    }

    .download-url {
      display: none;
    }
  }

  .msg-text {
    &.admin {
      color: ${COLORS.WHITE};
    }
  }
`;
