// CORE
import { Link } from "react-router-dom";
import { Button } from "antd";

//IMAGE
import Image from 'Assets/Images/DemoPic/rob.png'

//CUSTOM
import { PageNotFoundStyle } from "./PageNotFound.style";
// import Button from "Components/common/uielements/button";

const PageNotFound = () => {
  return (
    <PageNotFoundStyle className="iso404Page">
      <div className="iso404Content">
        <h1>404</h1>
        <h3>Looks like you got lost</h3>
        <p>the page you are looking for doesn't exist or has been moved.</p>
        <Link to="/">
          <Button type="primary">BACK HOME</Button>
        </Link>
      </div>

      <div className="iso404Artwork">
        <img src={Image} alt="Not Found" />
      </div>
    </PageNotFoundStyle>
  );
};

export default PageNotFound;
