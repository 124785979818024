import React, { useRef } from "react";
import { Typography } from "antd";
import { FilePdfFilled } from "@ant-design/icons";
import { MdFileDownload } from "react-icons/md";
import { CHAT_MSG_SEND_FROM } from "Helpers/Constants";
import { PdfMsgWrapper } from "./PdfMessage.style";

const PdfMessage = ({ item }) => {
  const downloadUrlRef = useRef(null);

  return (
    <PdfMsgWrapper
      className={`file-msg ${
        item?.sendFrom === CHAT_MSG_SEND_FROM.ADMIN ? "admin-msg" : "user-msg"
      }`}
    >
      <div className="flex pdf-wrapper f-h-center f-v-center">
        <FilePdfFilled className="pdf-icon-size" />
        <Typography
          className={`pdf-file-name ${
            item?.sendFrom === CHAT_MSG_SEND_FROM.ADMIN ? "admin" : "user"
          }`}
        >
          {item?.fileName}
        </Typography>
        <MdFileDownload
          className="download-icon hover"
          onClick={() => downloadUrlRef?.current?.click()}
        />
        <a
          href={item?.attachmentFileURL}
          ref={downloadUrlRef}
          className="download-url"
        >
          click me
        </a>
      </div>
      <Typography
        className={`msg-text ${
          item?.sendFrom === CHAT_MSG_SEND_FROM.ADMIN ? "admin" : "user"
        }`}
      >
        {item?.message}
      </Typography>
    </PdfMsgWrapper>
  );
};

export default PdfMessage;
