//CORE
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Segmented, Spin, Table, Tooltip, notification } from "antd";
import moment from "moment";
import * as XLSX from "xlsx";

//ICONS
import { PlusCircleOutlined, EyeOutlined } from "@ant-design/icons";

//WRAPPER
import {
  LayoutContentStyle,
  LayoutContentWrapper,
  PageHeader,
} from "Components/Common/Common.style";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import {
  MODAL_TYPE,
  PAGINATION,
  USER_ACCOUNT_PENDING_VALUE,
  USER_SHEET_NAME,
  USER_TYPE,
} from "Helpers/Constants";
import {
  sortNumber,
  getColumnSearchProps,
  getFieldKeyValue,
  createMobileNo,
} from "Helpers/Utils";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import AddEditUserModal from "../Modal/AddEditUserModal";
import ViewUserManagement from "../Drawer/ViewUserManagement";
import { RegisteredUserWrapper } from "./RegisteredUser.style";

const PAGINATION_INIT = {
  currentPage: 1,
  perPage: PAGINATION.PER_PAGE,
  filter: {
    field: "userId",
    order: "ASC",
  },
  search: {
    searchField: "",
    searchBy: "",
  },
};

const RegisteredUser = () => {
  const API = useMemo(() => new Api(), []);
  const fileUploadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState(PAGINATION_INIT);
  const [userData, setUserData] = useState({
    data: [],
    totalRecords: 0,
  });
  const [userModal, setUserModal] = useState({
    data: null,
    visible: false,
    type: MODAL_TYPE.ADD,
  });
  const [pendingStatus, setPendingStatus] = useState(
    USER_TYPE.CMS_USER_CREATED_REGISTERED_USER
  );
  const [userDrawer, setUserDrawer] = useState({
    data: null,
    visible: false,
  });
  const [resetData, setResetData] = useState(false);

  const getRegisteredUser = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await API.post(API_URL.GET_REGISTERED_USER, {
        data: {
          pageNumber: paginationInfo.currentPage,
          totalRecordPerPage: paginationInfo.perPage,
          orderBy: paginationInfo.filter.field,
          order: paginationInfo.filter.order,
          searchField: paginationInfo.search.searchField,
          searchBy: paginationInfo.search.searchBy,
          customerType: pendingStatus,
        },
      });

      if (response.status === CODES.SUCCESS) {
        setIsLoading(false);
        setUserData({
          data: response?.data?.list,
          totalRecords: response.data.totalElements,
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [API, paginationInfo, pendingStatus]);

  const handleOpenModal = (type = MODAL_TYPE.ADD, data) => {
    setUserModal((prev) => {
      return { ...prev, visible: true, data, type };
    });
  };

  const handleCloseModal = (shouldUpdate = false) => {
    setUserModal((prev) => {
      return { ...prev, data: null, visible: false };
    });
    shouldUpdate && setResetData((prev) => !prev);
  };

  const handleOpenDrawer = (data) => {
    setUserDrawer((prev) => {
      return { ...prev, data, visible: true };
    });
  };

  const handleCloseDrawer = () => {
    setUserDrawer((prev) => {
      return { ...prev, data: null, visible: false };
    });
  };

  const tableFiltersUpdated = (pagination, filters, sorter) => {
    let sorterOrder = "ASC";
    if (sorter.field)
      switch (sorter.order) {
        case "ascend":
          sorterOrder = "ASC";
          break;
        case "descend":
          sorterOrder = "DESC";
          break;
        default:
          sorterOrder = "ASC";
          sorter.field = "userId";
          break;
      }

    const searchValues = getFieldKeyValue(filters);

    setPaginationInfo((prev) => {
      return {
        ...prev,
        currentPage: pagination.current ? pagination.current : 1,
        perPage: pagination?.pageSize || PAGINATION.PER_PAGE,
        filter: {
          field: sorter.field || "userId",
          order: sorterOrder || "Asc",
        },
        search: {
          searchField: searchValues.filterKey,
          searchBy: searchValues.filterValue,
        },
      };
    });
  };

  const registeredUserListUI = () => {
    const paginationConfig = {
      pageSize: paginationInfo.perPage,
      total: userData.totalRecords,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} of ${total} records`,
      current: paginationInfo.currentPage,
      showSizeChanger: true,
    };

    const columns = [
      {
        title: "Sr.No",
        dataIndex: "id",
        key: "id",
        rowScope: "row",
        fixed: "left",
        width: 70,
        render: (text, record, index) => (
          <span>
            {(paginationInfo.currentPage - 1) * paginationInfo.perPage +
              index +
              1}
          </span>
        ),
      },
      {
        title: "User Name",
        dataIndex: "fullName",
        key: "fullName",
        width: 150,
        sorter: (a, b) => sortNumber(a, b, "fullName"),
        ...getColumnSearchProps("fullName", "Full Name"),
        render: (text, record, index) => (
          <span style={{ textTransform: "capitalize" }}>{text}</span>
        ),
      },
      {
        title: "Date of Birth",
        dataIndex: "createdDate",
        key: "createdDate",
        width: 150,
        render: (text, record, index) => (
          <span>{moment(new Date(text)).format("DD/MM/yy")}</span>
        ),
      },
      {
        title: "Mobile No",
        dataIndex: "mobileNumber",
        key: "mobileNumber",
        width: 150,
        ...getColumnSearchProps("mobileNumber", "Mobile no"),
        render: (text, record, index) => {
          return (
            <span>
              {createMobileNo(record?.countryCode, record?.mobileNumber)}
            </span>
          );
        },
      },
      {
        title: "Email",
        dataIndex: "emailId",
        key: "emailId",
        width: 250,
        ...getColumnSearchProps("emailId", "Email"),
      },
      {
        title: "Address",
        dataIndex: "fullAddress",
        key: "fullAddress",
        width: 200,
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        fixed: "right",
        width: 100,
        render: (text, record, index) => (
          <div className="action-button-container">
            <div className="item">
              <Tooltip placement="top" title="View">
                <Button
                  className="ant-btn-sm"
                  onClick={() => handleOpenDrawer(record)}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>
            </div>
          </div>
        ),
      },
    ];
    return (
      <Table
        dataSource={userData.data}
        columns={columns}
        rowKey={"userId"}
        style={{ width: "100%" }}
        loading={{ indicator: <Spin />, spinning: isLoading }}
        scroll={{ x: 700 }}
        onChange={tableFiltersUpdated}
        pagination={
          userData.totalRecords > PAGINATION.PER_PAGE ? paginationConfig : false
        }
      />
    );
  };

  const handleFileChange = (event) => {
    try {
      setIsLoading(true);
      //CONVERT XLSX FILE INTO JSON DATA
      let selectedFile = event.target?.files[0];
      if (selectedFile) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = async (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "buffer", cellDates: true });
          if (wb.SheetNames.includes(USER_SHEET_NAME.EXISTING_USER)) {
            // const workSheetName = wb.SheetNames[4]; //if we are doing this by sheet position in file than use this line
            const workSheet = wb.Sheets[USER_SHEET_NAME.EXISTING_USER];
            const data = XLSX.utils.sheet_to_json(workSheet);
            data.forEach((item) => {
              item["dob"] = moment(item.dob).format("yyyy-MM-DD");
              item["investmentStartDate"] = moment(
                item.investmentStartDate
              ).format("yyyy-MM-DD");
            });
            // API CALL. UPLOAD JSON FILE
            const response = await API.post(API_URL.CSV.UPLOAD_OLD_USER, {
              data: {
                investmentDetails: data,
              },
            });
            if (response.status === CODES.SUCCESS)
              notification.success({
                message: "Success",
                description: response?.message,
              });
          } else {
            notification.warning({
              message: "Warning",
              description: `Selected file don't have a worksheet with name "${USER_SHEET_NAME.EXISTING_USER}". Please add sheetName and then try again`,
            });
          }
          setIsLoading(false);
          fileUploadRef.current.value = null;
        };
      } else {
        notification.warning({
          message: "Warning",
          description: `please select you file`,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      fileUploadRef.current.value = null;
    }
  };

  const handlePendingStatus = (value) => {
    if (paginationInfo.currentPage !== 1) setPaginationInfo(PAGINATION_INIT);
    setPendingStatus(value);
  };

  useEffect(() => {
    getRegisteredUser();
  }, [getRegisteredUser, resetData]);

  return (
    <LayoutContentWrapper>
      <LayoutContentStyle>
        <RegisteredUserWrapper>
          <div className="title-container">
            <PageHeader>Registered User</PageHeader>
            <Button
              type="primary"
              onClick={() => handleOpenModal(MODAL_TYPE.ADD, null)}
            >
              Add <PlusCircleOutlined />
            </Button>
          </div>
          <div className="flex f-h-space-between action-btn-section">
            <Segmented
              options={[
                {
                  value: USER_TYPE.CMS_USER_CREATED_REGISTERED_USER,
                  label: USER_ACCOUNT_PENDING_VALUE.EXISTING_USER,
                },
                {
                  value: USER_TYPE.APP_SIGN_UP_REGISTERED_USER,
                  label: USER_ACCOUNT_PENDING_VALUE.NEW_USER,
                },
              ]}
              value={pendingStatus}
              onChange={handlePendingStatus}
            />
            {pendingStatus === USER_TYPE.CMS_USER_CREATED_REGISTERED_USER && (
              <>
                <Button
                  type="primary"
                  onClick={() => fileUploadRef?.current?.click()}
                >
                  Upload CSV
                </Button>
                <input
                  ref={fileUploadRef}
                  onChange={handleFileChange}
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  style={{ display: "none" }}
                />
              </>
            )}
          </div>
          <div className="table-container">{registeredUserListUI()}</div>
          {userModal.visible && (
            <AddEditUserModal
              modalData={userModal}
              closeModal={handleCloseModal}
            />
          )}
          {userDrawer.visible && (
            <ViewUserManagement
              drawerData={userDrawer}
              closeDrawer={handleCloseDrawer}
            />
          )}
        </RegisteredUserWrapper>
      </LayoutContentStyle>
    </LayoutContentWrapper>
  );
};

export default RegisteredUser;
