//CORE
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import {
  API_URL,
  URL_MINIMUM_INVESTMENT_AMOUNT,
  URL_PRIVACY_POLICY,
  URL_TERMS_AND_CONDITIONS,
} from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { STATIC_PAGE_PARAMS } from "Helpers/Constants";

const StaticPageUI = () => {
  const API = useMemo(() => new Api(), []);
  const location = useLocation();
  const path = location.pathname;
  const [isLoading, setIsLoading] = useState(false);
  const [staticPageData, setStaticPageData] = useState({
    pageContent: "",
    pageName: "",
    staticContentId: "",
  });

  const getStaticPageParams = useCallback(() => {
    switch (path) {
      case URL_TERMS_AND_CONDITIONS:
        return STATIC_PAGE_PARAMS.TERM_AND_CONDITIONS;
      case URL_PRIVACY_POLICY:
        return STATIC_PAGE_PARAMS.PRIVACY_POLICY;
      case URL_MINIMUM_INVESTMENT_AMOUNT:
        return STATIC_PAGE_PARAMS.MINIMUM_INVESTMENT_AMOUNT;
      default:
        return STATIC_PAGE_PARAMS.TERM_AND_CONDITIONS;
    }
  }, [path]);

  const getStaticPage = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await API.get(API_URL.STATIC_PAGE.GET_WITHOUT_SIGN_IN, {
        params: {
          pageName: getStaticPageParams(),
        },
      });
      if (response.status === CODES.SUCCESS) {
        setStaticPageData({ ...response?.data });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [API, getStaticPageParams]);

  useEffect(() => {
    getStaticPage();
  }, [getStaticPage]);

  return (
    <div>
      <Spin spinning={isLoading}>
        <div style={{ margin: "20px" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: staticPageData?.pageContent,
            }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default StaticPageUI;
