import { COLORS } from "Styles/Constants";
import styled from "styled-components";

export const TransactionLogWrapper = styled.div`
  .back-icon {
    margin-right: 10px;
  }

  .transaction-log-details {
    /* border: 1px solid red; */
    background: ${COLORS.PRIMARY_LIGHT};
    margin: 10px 0;
    padding: 10px;

    .text {
      color: ${COLORS.WHITE};
    }

    .total-invested-section {
      .total-invested-amount {
        font-size: 32px;
      }
    }

    .divider {
      background: ${COLORS.WHITE};
      height: auto;
    }

    .investment-details {
      .fields {
        .key {
          font-size: 16px;
          font-weight: 800;
          min-width: 130px;
        }

        .separator {
          margin: 0 5px;
        }

        .value {
          font-size: 16px;

          &.green {
            color: ${COLORS.SUCCESS};
          }

          &.red {
            color: ${COLORS.ERROR};
          }
        }
      }
    }
  }
`;
