import { useEffect, useState } from "react";
import { Image, Typography } from "antd";
import { FilePreviewWrapper } from "./FilePreview.style";
import { FALLBACK_IMG } from "Helpers/Constants";
import { BiSolidFilePdf } from "react-icons/bi";
import { FaTrash } from "react-icons/fa6";

const IMAGE_TYPE_ARR = ["image/png", "image/jpeg", "image/jpg"];

const FilePreview = (props) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (props?.file && IMAGE_TYPE_ARR.includes(props?.file?.type)) {
      setImage(URL.createObjectURL(props?.file));
    }
  }, [props.file]);

  return (
    <FilePreviewWrapper
      className="flex f-v-center f-h-space-between"
      $largeIcon={IMAGE_TYPE_ARR.includes(props?.file?.type)}
    >
      <div className="flex f-v-center file-prev">
        {IMAGE_TYPE_ARR.includes(props?.file?.type) ? (
          <Image
            width={image ? 200 : 100}
            height={100}
            src={image || FALLBACK_IMG}
          />
        ) : (
          <BiSolidFilePdf className="pdf-icon" />
        )}
        <Typography className="filename">{props.file.name}</Typography>
      </div>
      <FaTrash
        className="trash-icon hover"
        onClick={() => props.removeFile()}
      />
    </FilePreviewWrapper>
  );
};

export default FilePreview;
