//CORE
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Spin, Table, Tooltip } from "antd";

//WRAPPER
import {
  LayoutContentStyle,
  LayoutContentWrapper,
  PageHeader,
} from "Components/Common/Common.style";

//ICON
import { EditOutlined } from "@ant-design/icons";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import EditSocialMedia from "./Modal/EditSocialMedia";
// import { sortNumber } from "Helpers/Utils";

const SocialMedia = () => {
  const API = useMemo(() => new Api(), []);
  const [isLoading, setIsLoading] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState({
    data: [],
    totalRecords: 0,
  });
  const [socialMediaModal, setSocialMediaModal] = useState({
    data: null,
    visible: false,
  });
  const [resetData, setResetData] = useState(false);

  const getSocialMediaData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await API.get(API_URL.SOCIAL_MEDIA.GET);

      if (response.status === CODES.SUCCESS) {
        setSocialMediaData({
          data: response?.data,
          totalRecords: response.data.length,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [API]);

  const handleOpenModal = (data) => {
    setSocialMediaModal((prev) => {
      return { ...prev, visible: true, data };
    });
  };

  const handleCloseModal = (shouldUpdate = false) => {
    setSocialMediaModal((prev) => {
      return { ...prev, data: null, visible: false };
    });
    shouldUpdate && setResetData((prev) => !prev);
  };

  const socialMediaListUI = () => {
    // const paginationConfig = {
    //   // pageSize: paginationInfo.perPage,
    //   total: socialMediaData.totalRecords,
    //   showTotal: (total, range) =>
    //     `${range[0]}-${range[1]} of ${total} records`,
    //   // current: paginationInfo.currentPage,
    //   showSizeChanger: true,
    // };

    const columns = [
      {
        title: "Sr.No",
        dataIndex: "id",
        key: "id",
        rowScope: "row",
        fixed: "left",
        width: 70,
        render: (text, record, index) => (
          <span>
            {index + 1}
          </span>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 100,
        // sorter: (a, b) => sortNumber(a, b, "name"),
      },
      {
        title: "URL",
        dataIndex: "url",
        key: "url",
        width: 250,
      },
      {
        title: "Action",
        key: "action",
        fixed: "right",
        width: 100,
        render: (text, record, index) => (
          <div className="action-button-container">
            <div className="item">
              <Tooltip placement="top" title="Edit">
                <Button
                  className="btn-info btn-sm ant-btn-sm"
                  onClick={() => handleOpenModal(record)}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            </div>
          </div>
        ),
      },
    ];

    return (
      <Table
        dataSource={socialMediaData.data}
        columns={columns}
        rowKey={"id"}
        style={{ width: "100%" }}
        loading={{ indicator: <Spin />, spinning: isLoading }}
        scroll={{ x: 700 }}
        pagination={false}
      />
    );
  };

  useEffect(() => {
    getSocialMediaData();
  }, [getSocialMediaData, resetData]);

  return (
    <LayoutContentWrapper>
      <LayoutContentStyle>
        <div className="title-container">
          <PageHeader>Social Media</PageHeader>
        </div>
        <div className="table-container">{socialMediaListUI()}</div>
        {socialMediaModal.visible && (
          <EditSocialMedia
            modalData={socialMediaModal?.data}
            closeModal={handleCloseModal}
          />
        )}
      </LayoutContentStyle>
    </LayoutContentWrapper>
  );
};

export default SocialMedia;
