//CORE
import { useMemo, useRef, useState } from "react";
import { Button, Form, Input, Modal, Spin, Tag, notification } from "antd";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import { FormWrapper } from "Components/Common/Common.style";
import { FORM_PARAMS } from "Helpers/Constants";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { NotificationModalWrapper } from "./NotificationModal.style";

const { TextArea } = Input;

const NotificationModal = (props) => {
  const { modalData } = props;
  const API = useMemo(() => new Api(), []);
  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await API.put(API_URL.NOTIFICATION.EDIT, {
        data: { id: modalData.id, ...values },
      });
      if (response.status === CODES.SUCCESS) {
        notification.success({
          message: "Success",
          description: response?.message,
        });
        handleClose(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = (updateFlag = false, data = null) => {
    props.closeModal(updateFlag, data);
  };

  return (
    <Modal
      open
      title={"Edit Transaction Log"}
      okButtonProps={{ disabled: isLoading }}
      onCancel={() => handleClose()}
      footer={[
        <Button key="back" type="default" onClick={() => handleClose()}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => formRef.current?.submit()}
        >
          Update
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <NotificationModalWrapper>
          <Tag color={"red"} className="warning-tag">
            {`Note: Please do not change any word wrapped by { } braces`}
          </Tag>
          <FormWrapper
            ref={formRef}
            onFinish={handleSubmit}
            scrollToFirstError
            labelAlign="left"
            labelCol={{ span: FORM_PARAMS.LABEL_COL_SPAN }}
            labelWrap
            initialValues={{
              title: modalData?.title,
              body: modalData?.body,
            }}
          >
            <Form.Item
              name="title"
              key="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please enter notification title",
                },
              ]}
            >
              <Input placeholder="Enter notification title" />
            </Form.Item>
            <Form.Item
              name="body"
              key="body"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please enter notification description",
                },
              ]}
            >
              <TextArea
                rows={4}
                autoSize={{ minRows: 3, maxRows: 6 }}
                placeholder="Notification description"
              />
            </Form.Item>
          </FormWrapper>
        </NotificationModalWrapper>
      </Spin>
    </Modal>
  );
};

export default NotificationModal;
