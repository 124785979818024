//CORE
import { DrawerWrapper } from "Components/Common/Common.style";
import { Card, Drawer, Tag, Typography } from "antd";
import { Content } from "antd/es/layout/layout";

//CUSTOM
import { USER_ACCOUNT_PENDING_VALUE } from "Helpers/Constants";
import { createMobileNo } from "Helpers/Utils";
import moment from "moment";

const ViewUserManagement = (props) => {
  const { drawerData } = props;

  return (
    <Drawer
      open={drawerData.visible}
      title="View User Data"
      size="large"
      onClose={() => props.closeDrawer()}
      closable
    >
      <DrawerWrapper $keyWidth={115}>
        <Card className="card">
          <Typography className="title">User Details</Typography>
          <Content className="fields">
            <Typography className="key">User Name</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.fullName || "N/A"}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">User Type</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.isUserCreatedByCmsUser
                ? USER_ACCOUNT_PENDING_VALUE.EXISTING_USER
                : USER_ACCOUNT_PENDING_VALUE.NEW_USER}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Mobile No</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {createMobileNo(
                drawerData?.data?.countryCode,
                drawerData?.data?.mobileNumber
              )}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Email Id</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData?.data?.emailId || "N/A"}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">DOB</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {moment(drawerData?.data?.dob, "yyyy-MM-DD").format(
                "DD/MM/yyyy"
              ) || "N/A"}
            </Typography>
          </Content>
        </Card>
        <Card className="card">
          <Typography className="title">Address</Typography>
          <Content className="fields">
            <Typography className="key">Address Line 1</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.customerAddress?.addressLine1 || "N/A"}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Address Line 2</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.customerAddress?.addressLine2 || "N/A"}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Post Code</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.customerAddress?.postCode || "N/A"}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Town</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.customerAddress?.town || "N/A"}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Full Address</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.fullAddress || "N/A"}
            </Typography>
          </Content>
        </Card>
        <Card className="card">
          <Typography className="title">User Status</Typography>
          <Content className="fields">
            <Typography className="key">Email Verified</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.isAccountVerified ? (
                <Tag color="green">Yes</Tag>
              ) : (
                <Tag color="red">No</Tag>
              )}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Active</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.isActive ? (
                <Tag color="green">Yes</Tag>
              ) : (
                <Tag color="red">No</Tag>
              )}
            </Typography>
          </Content>
        </Card>
      </DrawerWrapper>
    </Drawer>
  );
};

export default ViewUserManagement;
