import { CHAT_MSG_SEND_FROM } from "Helpers/Constants";
import { TextMsgWrapper } from "./TextMessage.style";

const TextMessage = ({ item }) => {
  return (
    <TextMsgWrapper
      className={`msg ${
        item?.sendFrom === CHAT_MSG_SEND_FROM.ADMIN ? "admin-msg" : "user-msg"
      }`}
    >
      {item?.message}
    </TextMsgWrapper>
  );
};

export default TextMessage;
