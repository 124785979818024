//CORE
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";

//CUSTOM
import { SidebarWrapper } from "./Sidebar.style";
import { changeCurrent, toggleDrawer } from "Redux/App/Actions";
import MENU_ITEM from "Helpers/Options";
import { DASHBOARD_KEY } from "Helpers/Constants";
import Logo from "./Logo";

const { Sider } = Layout;

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const collapsed = useSelector((state) => state.App.collapsed);
  const openDrawer = useSelector((state) => state.App.openDrawer);
  const drawerCurrent = useSelector((state) => state.App.current);
  const DrawerCollapsed = collapsed && !openDrawer;
  const mode = DrawerCollapsed === true ? "vertical" : "inline";

  const onMouseEnter = () => {
    if (openDrawer === false) {
      dispatch(toggleDrawer());
    }
    return;
  };

  const onMouseLeave = () => {
    if (openDrawer === true) {
      dispatch(toggleDrawer());
    }
    return;
  };

  const handleMenu = (e) => {
    dispatch(changeCurrent([e.key]));
    navigate({ pathname: e.key });
  };

  return (
    <SidebarWrapper>
      <Sider
        trigger={null}
        collapsible={true}
        width={240}
        collapsed={DrawerCollapsed}
        className="isomorphicSidebar"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Logo collapsed={DrawerCollapsed} />
        <div className="options">
          <Menu
            className="isoDashboardMenu"
            theme={"dark"}
            mode={mode}
            onClick={handleMenu}
            defaultOpenKeys={[DASHBOARD_KEY]}
            selectedKeys={drawerCurrent}
            items={MENU_ITEM}
          ></Menu>
        </div>
      </Sider>
    </SidebarWrapper>
  );
};

export default Sidebar;
