//CORE
import React, { useEffect } from "react";
import { Routes, Route as ReactRoute, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//CUSTOM
import { Login } from "Components/Pages/Login";
import {
  URL_DASHBOARD,
  URL_FORGOT_PASSWORD,
  URL_LOGIN,
  URL_MINIMUM_INVESTMENT_AMOUNT,
  URL_PRIVACY_POLICY,
  URL_RESET_PASSWORD,
  URL_SIGN_IN,
  URL_TERMS_AND_CONDITIONS,
} from "Helpers/Paths";
import RoutesList from "./RouteList";
import ProtectedRoute from "./ProtectedRoute";
import { PageNotFound } from "Components/Pages/404";
import { ForgotPassword } from "Components/Pages/ForgotPassword";
import { ResetPassword } from "Components/Pages/ResetPassword";
import { StaticPageUI } from "Components/Pages/StaticPageUI";

const BEFORE_LOGIN_ACCESSIBLE_PATHS = [
  URL_LOGIN,
  URL_SIGN_IN,
  URL_FORGOT_PASSWORD,
  URL_RESET_PASSWORD,
  URL_TERMS_AND_CONDITIONS,
  URL_PRIVACY_POLICY,
  URL_MINIMUM_INVESTMENT_AMOUNT,
];

const Route = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);

  useEffect(() => {
    if (
      isLoggedIn &&
      BEFORE_LOGIN_ACCESSIBLE_PATHS.includes(window?.location?.pathname)
    ) {
      navigate({ pathname: URL_DASHBOARD });
    }
  }, [isLoggedIn, navigate]);

  return (
    <Routes>
      <ReactRoute path={URL_SIGN_IN} element={<Login />} />
      <ReactRoute path={URL_LOGIN} element={<Login />} />
      <ReactRoute path={URL_FORGOT_PASSWORD} element={<ForgotPassword />} />
      <ReactRoute path={URL_RESET_PASSWORD} element={<ResetPassword />} />
      <ReactRoute path={URL_TERMS_AND_CONDITIONS} element={<StaticPageUI />} />
      <ReactRoute path={URL_PRIVACY_POLICY} element={<StaticPageUI />} />
      <ReactRoute
        path={URL_MINIMUM_INVESTMENT_AMOUNT}
        element={<StaticPageUI />}
      />
      {RoutesList.map((route, index) => (
        <React.Fragment key={index}>
          {route.hasChildren ? (
            <ReactRoute
              path={route.path}
              element={<ProtectedRoute>{route.Component}</ProtectedRoute>}
            >
              {route.children}
            </ReactRoute>
          ) : (
            <ReactRoute
              path={route.path}
              element={<ProtectedRoute>{route.Component}</ProtectedRoute>}
            />
          )}
        </React.Fragment>
      ))}
      <ReactRoute path={"*"} element={<PageNotFound />} />
    </Routes>
  );
};

export default Route;
