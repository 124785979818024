//CORE
import React, { useMemo, useRef, useState } from "react";
import { Button, Form, Input, Spin, Typography, notification } from "antd";

//API
import Api from "Helpers/ApiHandler";

//TYPE
import {
  LayoutContentStyle,
  LayoutContentWrapper,
  PageHeader,
} from "Components/Common/Common.style";

//CUSTOM
import { COLORS } from "Styles/Constants";
import { UserNotificationWrapper } from "./UserNotification.style";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";

const { TextArea } = Input;

const UserNotification = () => {
  const formRef = useRef(null);
  const API = useMemo(() => new Api(), []);
  const [isLoading, setIsLoading] = useState(false);
  const [emailIds, setEmailIds] = useState({
    emails: [],
    value: "",
    error: null,
  });

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await API.post(API_URL.SEND_USER_NOTIFICATION, {
        data: {
          ...values,
          receiverEmailIds: emailIds.emails.map((email) => email.toLowerCase()),
        },
      });
      if (response.status === CODES.SUCCESS) {
        notification.success({
          message: "success",
          description: response?.message,
        });
        setEmailIds({
          emails: [],
          value: "",
          error: null,
        });
        formRef.current?.resetFields();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      let value = emailIds.value.trim();

      if (value && isValid(value)) {
        setEmailIds((prev) => {
          return { ...prev, emails: [...prev.emails, prev.value], value: "" };
        });
      }
    }
  };

  const handleChange = (e) => {
    if (!emailIds.emails.length && !Boolean(e.target.value)) {
      setEmailIds((prev) => {
        return {
          ...prev,
          value: e.target.value,
          error: "Please enter at least one email id",
        };
      });
      return null;
    }
    setEmailIds((prev) => {
      return { ...prev, value: e.target.value, error: null };
    });
  };

  const handlePaste = (e) => {
    e.preventDefault();

    let paste = e.clipboardData.getData("text");
    let emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      let toBeAdded = emails.filter((email) => !isInList(email));

      setEmailIds((prev) => {
        return { ...prev, emails: [...prev.emails, ...toBeAdded] };
      });
    } else {
      setEmailIds((prev) => ({
        ...prev,
        value: e.clipboardData.getData("text"),
      }));
    }
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setEmailIds((prev) => {
        return { ...prev, error };
      });

      return false;
    }

    return true;
  };

  const isInList = (email) => {
    return emailIds.emails.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
  };

  const handleDelete = (item) => {
    setEmailIds((prev) => {
      return { ...prev, emails: [...prev.emails.filter((i) => i !== item)] };
    });
  };

  const handleFormError = () => {
    if (Boolean(emailIds.value)) {
      if (isEmail(emailIds.value)) {
        setEmailIds((prev) => ({
          ...prev,
          value: "",
          emails: [...prev.emails, prev.value],
          error: null,
        }));
      } else {
        setEmailIds((prev) => ({
          ...prev,
          error: `${prev.value} is not a valid email address.`,
        }));
      }
      return null;
    }
    if (!emailIds.emails.length) {
      setEmailIds((prev) => ({
        ...prev,
        error: `Please enter at least one email id`,
      }));
    }
  };

  return (
    <LayoutContentWrapper>
      <LayoutContentStyle>
        <div className="title-container">
          <PageHeader>User Notification</PageHeader>
        </div>
        <UserNotificationWrapper>
          <Spin spinning={isLoading}>
            <Form
              ref={formRef}
              onFinish={handleSubmit}
              scrollToFirstError
              layout="vertical"
            >
              <Form.Item
                name="title"
                key="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Please enter your title",
                  },
                ]}
              >
                <Input placeholder="Enter title" />
              </Form.Item>
              <div className="email-field-wrapper">
                <div className="email-list">
                  {emailIds.emails.map((item) => (
                    <div className="tag-item" key={item}>
                      {item}
                      <button
                        type="button"
                        className="button"
                        onClick={() => handleDelete(item)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
                <Typography>
                  <span style={{ color: COLORS.ERROR, marginRight: "4px" }}>
                    *
                  </span>
                  Email
                </Typography>
                <Input
                  className={"input"}
                  value={emailIds.value}
                  placeholder="Type or paste email address and press `Enter`..."
                  status={`${emailIds.error ? "error" : ""}`}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  onPaste={handlePaste}
                />
                <p className="error">{emailIds.error}</p>
              </div>
              <Form.Item
                name={"message"}
                key={"message"}
                label="Message"
                rules={[
                  { required: true, message: "Please enter your message" },
                ]}
              >
                <TextArea
                  rows={4}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  placeholder="Enter your message"
                />
              </Form.Item>
              <Button
                disabled={isLoading}
                onClick={() => {
                  handleFormError();
                  formRef.current?.submit();
                }}
                type="primary"
              >
                Submit
              </Button>
            </Form>
          </Spin>
        </UserNotificationWrapper>
      </LayoutContentStyle>
    </LayoutContentWrapper>
  );
};

export default UserNotification;
