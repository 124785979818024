//CORE
import { useState } from "react";
import { Divider, Popover } from "antd";
// import { Link } from "react-router-dom";

//IMG
import userPic from "Assets/Images/DemoPic/user1.png";
import { TopBarDropdownWrapper } from "./TopBarUser.style";
import LogoutModal from "./Modal/LogoutModal";
import { Link } from "react-router-dom";
import { URL_ADMIN_PROFILE } from "Helpers/Paths";
// import { URL_ADMIN_PROFILE } from "Helpers/Paths";

const TopBarUser = () => {
  const [visible, setVisible] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  const handleVisibleChange = () => {
    setVisible((prev) => !prev);
  };

  const handleOpenModal = () => {
    setLogoutModal(true);
    handleVisibleChange();
  };

  const content = (
    <TopBarDropdownWrapper className="isoUserDropdown">
      <Link
        className="isoDropdownLink"
        to={URL_ADMIN_PROFILE}
        onClick={handleVisibleChange}
      >
        <i className="ion-android-person nav-icon" />
        <span>Profile</span>
      </Link>
      <Divider className="divider" />
      <div className="isoDropdownLink" onClick={handleOpenModal}>
        <i className="ion-android-exit nav-icon" />
        <span>Logout</span>
      </div>
    </TopBarDropdownWrapper>
  );

  return (
    <>
      <Popover
        content={content}
        trigger="click"
        open={visible}
        onOpenChange={handleVisibleChange}
        arrow={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <img alt="user" src={userPic} />
          <span className="userActivity online" />
        </div>
      </Popover>
      {logoutModal && <LogoutModal closeModal={() => setLogoutModal(false)} />}
    </>
  );
};

export default TopBarUser;
