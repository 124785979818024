import { COLORS } from "Styles/Constants";
import styled from "styled-components";

export const WebsiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 100%;

  .content-layout {
    overflow: hidden;
    overflow-x: hidden;
  }

  .footer-style {
    background: #fff;
    text-align: center;
    border-top: 1px solid ${COLORS.GREY};
  }

  // theme style
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${COLORS.PRIMARY};
      color: #fff;
    }
  }

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .ant-pagination-total-text {
    display: flex;
    flex-grow: 1;
  }
`;

export const ContentWrapper = styled.div`
  /* background-color: #fff; */
  background: #f1f3f6;
  overflow-x: auto;
  height: ${(props) =>
    props?.$ShowSideBar ? `calc(100vh - ${props?.$mobileHeight}px)` : `100vh`};
`;
