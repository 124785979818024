//CORE
import { useRef, useState } from "react";
import { Button, Form, Input, Modal, Spin } from "antd";

//CUSTOM
import { FormWrapper } from "Components/Common/Common.style";
import { FORM_PARAMS } from "Helpers/Constants";

const WithdrawalRejectModal = (props) => {  
  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      props.closeModal(true, props?.modalData?.data?.id, values?.remark);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = (updateFlag = false, data = null) => {
    props.closeModal(updateFlag, data);
  };

  return (
    <Modal
      open
      title={"Reject Withdrawal Request"}
      okButtonProps={{ disabled: isLoading }}
      onCancel={() => handleClose()}
      footer={[
        <Button key="back" type="default" onClick={() => handleClose()}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => formRef.current?.submit()}
        >
          Reject
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <FormWrapper
          ref={formRef}
          onFinish={handleSubmit}
          scrollToFirstError
          labelAlign="left"
          labelCol={{ span: FORM_PARAMS.LABEL_COL_SPAN }}
          labelWrap
        >
          <Form.Item name="remark" key="remark" label="Remark">
            <Input placeholder="Enter withdrawal remark" />
          </Form.Item>
        </FormWrapper>
      </Spin>
    </Modal>
  );
};

export default WithdrawalRejectModal;
