import styled from "styled-components";
import BgImage from "Assets/Images/loginGradiant.png";
import SiteIcon from "Assets/Images/SRInvestmentWithoutBg.png";
import { COLORS, responsive } from "Styles/Constants";

export const LoginWrapper = styled.div`
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 100vh;
  width: 100vw;

  .login-form-wrapper {
    /* max-width: 60%; */
    /* max-height: 70%; */
    background: linear-gradient(
      134.86deg,
      rgba(255, 255, 255, 0.22) 2.78%,
      rgba(255, 255, 255, 0.19) 100%
    );
    border-radius: 8px;
    border: 2px solid #ffffff50;
    /* border-image-source: linear-gradient(
      134.86deg,
      rgba(255, 255, 255, 0.22) 2.78%,
      rgba(255, 255, 255, 0.19) 100%
    ); */
    box-shadow: 0px 2px 5px 0px rgba(95, 133, 205, 0.05);
    box-shadow: 0px 9px 9px 0px rgba(95, 133, 205, 0.04);
    box-shadow: 0px 20px 12px 0px rgba(95, 133, 205, 0.02);
    box-shadow: 0px 36px 14px 0px rgba(95, 133, 205, 0.01);
    box-shadow: 0px 56px 16px 0px rgba(95, 133, 205, 0);
    padding: 50px;
    max-width: 400px;
    width: 50%;

    .ant-form-item-required {
      &::before {
        visibility: hidden;
        position: absolute;
      }

      &::after {
        content: "*";
        margin-left: 4px;
        color: ${COLORS.ERROR};
        visibility: visible;
      }
    }

    ${responsive.TABLET_700`
        width: 60%;
    `}

    ${responsive.PHABLET_500`
        width: 70%;
        padding: 40px;
    `}
    
    ${responsive.MOBILE_376`
        width: 75%;
        padding: 30px;
    `}

    .logo {
      background-image: url(${SiteIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      height: 80px;
      width: 180px;
      margin: 0 auto;

      ${responsive.PHABLET_500`
        height: 70px;
        width: 160px;
      `}

      ${responsive.MOBILE_376`
        height: 60px;
        width: 150px;
      `}
    }

    .login-form {
      margin-top: 30px;

      .form-item {
        &.pass-item {
          margin: 0;
        }

        .input-field {
          border-radius: 4px;
        }
      }

      .forgot-password-text {
        margin: 10px 0 20px 0;
        text-align: end;
      }

      .sign-in-btn {
        width: 100%;
      }
    }
  }
`;
