//CORE
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Input, Modal, Spin, notification } from "antd";
import ReactQuill from "react-quill";

//CUSTOM
import Api from "Helpers/ApiHandler";
import { FORM_PARAMS, REGEX } from "Helpers/Constants";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { FormWrapper } from "Components/Common/Common.style";
import { StaticPageModalWrapper } from "./StaticPageModal.style";

//REACT QUILL SETTINGS PARAMS
const Font = ReactQuill.Quill.import("formats/font"); // <<<< ReactQuill exports it
Font.whitelist = ["Roboto", "Raleway"]; // allow ONLY these fonts and the default
ReactQuill.Quill.register(Font, true);

const FORMATS = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
  "image",
  "background",
  "align",
  "size",
  "clean",
];

const MODULES = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: Font.whitelist }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ align: ["right", "center", "justify"] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    [{ color: [] }],
    [{ background: [] }],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const StaticPageModal = (props) => {
  const { modalData } = props;
  const API = useMemo(() => new Api(), []);
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await API.put(API_URL.STATIC_PAGE.EDIT, {
        data: {
          staticContentId: modalData?.staticContentId,
          pageContent: values?.pageContent,
        },
      });
      if (response.status === CODES.SUCCESS) {
        notification.success({
          message: "Success",
          description: response?.message,
        });
        handleClose(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = (updateFlag = false) => {
    props.closeModal(updateFlag);
  };

  useEffect(() => {
    formRef.current?.setFieldsValue({
      pageName: modalData?.pageName || "",
      pageContent: modalData?.pageContent || "",
    });
  }, [modalData?.pageContent, modalData?.pageName]);

  return (
    <Modal
      open
      width="70%"
      title={"Edit Static Page"}
      okButtonProps={{ disabled: isLoading }}
      onCancel={() => handleClose()}
      footer={[
        <Button key="back" type="default" onClick={() => handleClose()}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => formRef.current?.submit()}
        >
          Update
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <StaticPageModalWrapper>
          <FormWrapper
            ref={formRef}
            onFinish={handleSubmit}
            scrollToFirstError
            labelAlign="left"
            className="form"
            labelCol={{ span: FORM_PARAMS.LABEL_COL_SPAN }}
            labelWrap
          >
            <Form.Item name="pageName" key="pageName" label="Title">
              <Input placeholder="Enter static page title" disabled={true} />
            </Form.Item>
            <Form.Item
              name="pageContent"
              key="pageContent"
              rules={[
                {
                  required: true,
                  message: "Please enter description.",
                },
              ]}
              label="Description"
              onChange={(e) => {
                modalData?.pageName === "Minimum Investment Amount" &&
                  formRef.current?.setFieldValue(
                    "pageContent",
                    e?.target?.value.replace(REGEX.NUMBER, "")
                  );
              }}
            >
              {modalData?.pageName === "Minimum Investment Amount" ? (
                <Input placeholder="Enter description" />
              ) : (
                <ReactQuill formats={FORMATS} modules={MODULES} />
              )}
            </Form.Item>
          </FormWrapper>
        </StaticPageModalWrapper>
      </Spin>
    </Modal>
  );
};

export default StaticPageModal;
