//CORE
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  Label,
  LineChart,
  Line,
  LabelList
} from "recharts";
import { Card, Col, Row, Spin } from "antd";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import { StickerWidget } from "Components/Common/StickerWidget";
import {
  LayoutContentWrapper,
  WidgetWrapper,
} from "Components/Common/Common.style";
import { DashboardWrapper } from "./Dashboard.style";
import { COLORS } from "Styles/Constants";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { UKCurrencyConverter } from "Helpers/Utils";

const Dashboard = () => {
  const API = useMemo(() => new Api(), []);

  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getDashboardData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await API.post(API_URL.DASHBOARD.GET);
      if (response.status === CODES.SUCCESS) {
        setDashboardData(response?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [API]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  return (
    <Spin spinning={isLoading}>
      <LayoutContentWrapper>
        <DashboardWrapper>
          <div className="flex admin-data-segment">
            <Row justify="start" className="grid-row flex">
              <Col lg={6} md={12} xs={24} className="grid-col">
                <WidgetWrapper>
                  <StickerWidget
                    number={UKCurrencyConverter.format(
                      dashboardData?.totalInvestment || 0
                    )}
                    text={"Investment"}
                    icon="ion-social-buffer"
                    // icon="ion-podium"
                    fontColor={COLORS.WHITE}
                    bgColor={COLORS.PRIMARY}
                  />
                </WidgetWrapper>
              </Col>
              <Col lg={6} md={12} xs={24} className="grid-col">
                <WidgetWrapper>
                  <StickerWidget
                    number={dashboardData?.totalNoOfActiveUser || 0}
                    text={"Verified Users"}
                    icon="ion-android-people"
                    fontColor={COLORS.WHITE}
                    bgColor={"#42A5F6"}
                  />
                </WidgetWrapper>
              </Col>

              {/* <Col lg={6} md={12} xs={24} className="grid-col">
              <WidgetWrapper>
                <StickerWidget
                  number={150}
                  text={"Stocks"}
                  icon="ion-android-people"
                  fontColor={COLORS.WHITE}
                  bgColor="#7ED320"
                />
              </WidgetWrapper>
            </Col>
            <Col lg={6} md={12} xs={24} className="grid-col">
              <WidgetWrapper>
                <StickerWidget
                  number={150}
                  text={"Message"}
                  icon="ion-android-people"
                  fontColor={COLORS.WHITE}
                  bgColor="#F75D81"
                />
              </WidgetWrapper>
            </Col> */}
            </Row>
          </div>
          <Row justify="space-between" className="grid-row flex chart-row">
            <Col lg={12} md={12} xs={24} className="grid-col">
              <Card className="chart-card">
                <ResponsiveContainer
                  width={"100%"}
                  height={300}
                  className="responsive-chart-container"
                >
                  <BarChart data={dashboardData?.planWiseInvestmentDetail}>
                    <XAxis dataKey="planName">
                      <Label
                        value="Plan Name"
                        offset={-5}
                        position="insideBottom"
                      />
                    </XAxis>
                    <YAxis>
                      <Label
                        value="Amount (£)"
                        angle={-90}
                        offset={-5}
                        position="insideLeft"
                      />
                    </YAxis>
                    <Bar
                      dataKey="totalAmountPlanWise"
                      barSize={30}
                      fill={COLORS.PRIMARY}
                      // fill="#8884d8"
                    >
                      <LabelList dataKey="totalAmountPlanWise" position="top" />
                    </Bar>
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <Tooltip cursor={false} />
                    {/* <Legend verticalAlign="top" height={36}/> */}
                  </BarChart>
                </ResponsiveContainer>
              </Card>
            </Col>
            <Col lg={12} md={12} xs={24} className="grid-col">
              <Card className="chart-card">
                <ResponsiveContainer
                  width={"100%"}
                  height={300}
                  className="responsive-chart-container"
                >
                  <LineChart data={dashboardData?.activeUserList}>
                    <Line
                      type="monotone"
                      dataKey="activeUsers"
                      stroke="#42A5F6"
                    />
                    <XAxis dataKey="monthName">
                      <Label
                        value="Month"
                        offset={-5}
                        position="insideBottom"
                      />
                    </XAxis>
                    <YAxis>
                      <Label
                        value="No of Users"
                        angle={-90}
                        position="insideLeft"
                      />
                    </YAxis>
                    <Tooltip />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </Col>
          </Row>
        </DashboardWrapper>
      </LayoutContentWrapper>
    </Spin>
  );
};

export default Dashboard;
