import styled from "styled-components";

export const StaticPageModalWrapper = styled.div`
  .ql-editor {
    /* max-height: 395px !important; */
  }

  .ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
  .ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
    font-family: "Roboto", cursive;
    content: "Roboto";
  }

  .ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
  .ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
    font-family: "Raleway", cursive;
    content: "Raleway";
  }

  .ql-font-Raleway {
    font-family: "Raleway";
  }

  .ql-font-Roboto {
    font-family: "Roboto";
  }
`;
