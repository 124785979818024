import { DrawerWrapper } from "Components/Common/Common.style";
import { handleFeedBackStatus } from "Helpers/Utils";
import { Card, Drawer, Typography } from "antd";
import { Content } from "antd/es/layout/layout";

const ViewFeedback = (props) => {
  const { drawerData } = props;

  return (
    <Drawer
      open={drawerData.visible}
      title="View Feedback"
      size="large"
      onClose={() => props.closeDrawer()}
      closable
    >
      <DrawerWrapper $keyWidth={130}>
        <Card className="card">
          <Typography className="title">Feedback Details</Typography>
          <Content className="fields">
            <Typography className="key">User Name</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.fullName || "N/A"}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Email Id</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.emailId || "N/A"}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Feedback Status</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {handleFeedBackStatus(drawerData.data?.feedbackStatusId)}
            </Typography>
          </Content>
          <Content className="fields">
            <Typography className="key">Feedback</Typography>
            <Typography className="separator">:</Typography>
            <Typography className="value">
              {drawerData.data?.feedback || "N/A"}
            </Typography>
          </Content>
        </Card>
      </DrawerWrapper>
    </Drawer>
  );
};

export default ViewFeedback;
