//CORE
import React from "react";
import { useSelector } from "react-redux";
import { Layout } from "antd";

//CUSTOM
import { ContentWrapper, WebsiteWrapper } from "./Website.style";
import { Sidebar } from "./Common/Sidebar";
import { TopBar } from "./Common/TopBar";
import { SITE_CONFIG } from "Helpers/Constants";
import Route from "Routes/Route";

const { Footer } = Layout;

const Website = () => {
  const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);

  return (
    <WebsiteWrapper>
      <Layout>
        <Layout>
          {isLoggedIn && <Sidebar />}
          <Layout className="content-layout">
            {isLoggedIn && <TopBar />}
              <ContentWrapper $ShowSideBar={isLoggedIn} $mobileHeight={138}>
                <Route />
              </ContentWrapper>
            {isLoggedIn && (
              <Footer className="footer-style">{SITE_CONFIG.footerText}</Footer>
            )}
          </Layout>
        </Layout>
      </Layout>
    </WebsiteWrapper>
  );
};

export default Website;
