import { COLORS } from "Styles/Constants";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { get } from "lodash";
import moment from "moment";

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const getSideBarCurrentTab = () => {
  if (window && window.location.pathname) {
    const routes = window.location.pathname.split("/");
    if (routes.length > 1) {
      const lastRoute = routes[routes.length - 1];
      if (lastRoute) return [lastRoute];
      return [];
    }
    return [];
  }
  return [];
};

export const sortNumber = (a, b, key) => {
  if (
    a[key] !== undefined &&
    b[key] !== undefined &&
    b[key] !== null &&
    a[key] !== null
  ) {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
  }
  return 0;
};

export const getColumnSearchProps = (dataIndex, displayName) => {
  let searchInput = null;

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => (searchInput = node)}
          placeholder={`Search ${displayName}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={(e) => {
            e.stopPropagation();
            confirm();
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => {
            confirm();
          }}
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters && clearFilters();
            confirm();
          }}
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <SearchOutlined
          type="filter"
          style={{ color: filtered ? COLORS.PRIMARY : undefined }}
        />
      );
    },
    onFilter: (value, record) => {
      if (typeof value === "string") {
        return get(record, dataIndex)
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
      return get(record, dataIndex).includes(value);
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.select());
      }
    },
  };
};

export const getFieldKeyValue = (filters) => {
  let searchField = {
    filterKey: "",
    filterValue: "",
  };

  let myFilters = { ...filters };
  delete myFilters.isActive;

  for (var key in myFilters) {
    if (
      myFilters[key] &&
      myFilters.hasOwnProperty(key) &&
      myFilters[key].length > 0
    ) {
      searchField.filterKey = key;
      searchField.filterValue =
        myFilters?.[key]?.length > 0
          ? myFilters[key]?.toString()
          : myFilters[key][0];
    }
  }

  return searchField;
};

export const createMobileNo = (countryCode = "", mobileNo = null) => {
  if (mobileNo) {
    const addSign = countryCode.includes("+") ? "" : "+";
    return countryCode ? addSign + countryCode.concat(" ", mobileNo) : mobileNo;
  } else return "N/A";
};

export const handleInvestmentAmount = (
  investMentData = [],
  sumString = "currentTotalInvestedAmount"
) => {
  if (!!investMentData.length) {
    let totalInvestedAmount = 0;
    investMentData.forEach(
      (item) => (totalInvestedAmount = totalInvestedAmount + item[sumString])
    );
    return totalInvestedAmount;
  }
  return 0;
};

export const UKCurrencyConverter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const disabledFutureDate = (current) => {
  // Set your maximum date here
  return current && current > moment().endOf("day");
};

export const handleFileType = (fileName) => {
  switch (fileName.split(".")[1]) {
    case "png":
      return "PNG";
    case "jpg":
      return "JPG";
    case "jpeg":
      return "JPEG";
    case "pdf":
      return "PDF";
    case "PDF":
      return "PDF";
    default:
      return "JPG";
  }
};

export const handleFeedBackStatus = (status) => {
  switch (status) {
    case 1:
      return "Open";
    case 2:
      return "Hold";
    case 3:
      return "Resolved";
    default:
      break;
  }
};
