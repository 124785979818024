//CORE
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";

//API
import Api from "Helpers/ApiHandler";

//ICONS
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

//CUSTOM
import { ResetPasswordWrapper } from "./ResetPassword.style";
import { API_URL, URL_LOGIN } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { REGEX } from "Helpers/Constants";

const ResetPassword = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const API = useMemo(() => new Api(), []);

  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await API.post(API_URL.RESET_PASSWORD, {
        data: {
          password: values.password,
          verificationToken: token,
        },
      });

      if (response.status === CODES.SUCCESS) {
        notification.success({
          message: "Success",
          description: response?.message,
        });
        setIsLoading(false);
        navigate({ pathname: URL_LOGIN });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = new URLSearchParams(window.location.search)?.get("token");
    if (token) setToken(token);
  }, []);

  return (
    <ResetPasswordWrapper className="flex f-v-center f-h-center">
      <div className="form-wrapper">
        <div className="logo"></div>
        <Form
          ref={formRef}
          onFinish={handleSubmit}
          scrollToFirstError
          layout="vertical"
          className="reset-password-form"
        >
          <Form.Item
            name="password"
            label="Password"
            required
            rules={[
              {
                validator: (_, values) => {
                  if (REGEX.PASSWORD.test(values)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      "Password must have at least 8 characters, one uppercase, one lowercase, one special character and one numeric character."
                    );
                  }
                },
              },
            ]}
          >
            <Input.Password
              size="large"
              className="input-field"
              autoFocus
              placeholder="Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please enter confirm password.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Your password and confirmation password do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              className="input-field"
              placeholder="Confirm Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="reset-password-btn"
          >
            Reset Password
          </Button>
        </Form>
      </div>
    </ResetPasswordWrapper>
  );
};

export default ResetPassword;
