//CORE
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Popconfirm,
  Segmented,
  Spin,
  Table,
  Tooltip,
  notification,
} from "antd";
import moment from "moment";

//WRAPPER
import {
  LayoutContentStyle,
  LayoutContentWrapper,
  PageHeader,
} from "Components/Common/Common.style";

//API
import Api from "Helpers/ApiHandler";

//ICONS
import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

//CUSTOM
import {
  PAGINATION,
  WITHDRAWAL_REQUEST_STATUS,
  WITHDRAWAL_REQUEST_TYPE,
  WITHDRAW_REQ_STATUS,
} from "Helpers/Constants";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { getFieldKeyValue, sortNumber } from "Helpers/Utils";
import { WithdrawalRequestWrapper } from "./WithdrawalRequest.style";
import { COLORS } from "Styles/Constants";
import WithdrawalRejectModal from "./Modal/WithdrawalRejectModal";

const PAGINATION_INIT = {
  currentPage: 1,
  perPage: PAGINATION.PER_PAGE,
  filter: {
    field: "id",
    order: "ASC",
  },
  search: {
    searchField: "",
    searchBy: "",
  },
};

const WithdrawalRequest = () => {
  const API = useMemo(() => new Api(), []);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState(PAGINATION_INIT);
  const [withdrawalRequestData, setWithdrawalRequestData] = useState({
    data: [],
    totalRecords: 0,
  });
  const [requestType, setRequestType] = useState(WITHDRAWAL_REQUEST_STATUS.ALL);
  const [resetData, setResetData] = useState(false);
  const [modalData, setModalData] = useState({
    data: null,
    visible: false,
  });

  const getWithDrawalData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await API.post(API_URL.WITHDRAWAL_REQUEST.GET, {
        data: {
          pageNumber: paginationInfo.currentPage,
          totalRecordPerPage: paginationInfo.perPage,
          orderBy: paginationInfo.filter.field,
          order: paginationInfo.filter.order,
          searchField: paginationInfo.search.searchField,
          searchBy: paginationInfo.search.searchBy,
          requestStatus: requestType,
        },
      });

      if (response?.status === CODES.SUCCESS) {
        setIsLoading(false);
        setWithdrawalRequestData({
          data: response?.data?.list,
          totalRecords: response.data.totalElements,
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [paginationInfo, API, requestType]);

  const handleOpenModal = (data) => {
    setModalData({
      visible: true,
      data,
    });
  };

  const handleCloseModal = (shouldUpdate = false, id = null, remark = "") => {
    setModalData({
      visible: false,
      data: null,
    });
    shouldUpdate &&
      id &&
      handleWithDrawRequest(id, WITHDRAW_REQ_STATUS.REQUEST_REJECTED, remark);
  };

  const handleRequestStatus = (status) => {
    switch (status) {
      case WITHDRAWAL_REQUEST_STATUS.REQUEST_SENT:
        return WITHDRAWAL_REQUEST_TYPE.REQUEST_SENT;
      case WITHDRAWAL_REQUEST_STATUS.PAYMENT_DONE:
        return WITHDRAWAL_REQUEST_TYPE.PAYMENT_DONE;
      case WITHDRAWAL_REQUEST_STATUS.REQUEST_REJECTED:
        return WITHDRAWAL_REQUEST_TYPE.REQUEST_REJECTED;
      case WITHDRAWAL_REQUEST_STATUS.REQUEST_CANCELLED:
        return WITHDRAWAL_REQUEST_TYPE.REQUEST_CANCELLED;
      case WITHDRAWAL_REQUEST_STATUS.PAYMENT_DUE:
        return WITHDRAWAL_REQUEST_TYPE.PAYMENT_DUE;
      default:
        break;
    }
  };

  const handleWithDrawRequest = async (
    withdrawalRequestId,
    status,
    remark = ""
  ) => {
    try {
      setIsLoading(true);
      const response = await API.post(API_URL.WITHDRAWAL_REQUEST.ACTION, {
        data: {
          withdrawalRequestId,
          status,
          remark,
        },
      });
      if (response.status === CODES.SUCCESS) {
        notification.success({
          message: "success",
          description: response.message,
        });
        setResetData((prev) => !prev);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const tableFiltersUpdated = (pagination, filters, sorter) => {
    let sorterOrder = "ASC";
    if (sorter.field)
      switch (sorter.order) {
        case "ascend":
          sorterOrder = "ASC";
          break;
        case "descend":
          sorterOrder = "DESC";
          break;
        default:
          sorterOrder = "ASC";
          sorter.field = "id";
          break;
      }

    const searchValues = getFieldKeyValue(filters);

    setPaginationInfo((prev) => {
      return {
        ...prev,
        currentPage: pagination.current ? pagination.current : 1,
        perPage: pagination?.pageSize || PAGINATION.PER_PAGE,
        filter: {
          field: sorter.field || "id",
          order: sorterOrder || "Asc",
        },
        search: {
          searchField: searchValues.filterKey,
          searchBy: searchValues.filterValue,
        },
      };
    });
  };

  const withdrawalRequestListUI = () => {
    const paginationConfig = {
      pageSize: paginationInfo.perPage,
      total: withdrawalRequestData.totalRecords,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} of ${total} records`,
      current: paginationInfo.currentPage,
      showSizeChanger: true,
    };

    const columns = [
      {
        title: "Sr.No",
        dataIndex: "id",
        key: "id",
        rowScope: "row",
        fixed: "left",
        width: 70,
        render: (text, record, index) => (
          <span>
            {(paginationInfo.currentPage - 1) * paginationInfo.perPage +
              index +
              1}
          </span>
        ),
      },
      {
        title: "User Name",
        dataIndex: "fullName",
        key: "fullName",
        width: 150,
        sorter: (a, b) => sortNumber(a, b, "fullName"),
        render: (text, record, index) => (
          <span style={{ textTransform: "capitalize" }}>{text}</span>
        ),
      },
      {
        title: "Fund Name",
        dataIndex: "fundName",
        key: "fundName",
        width: 150,
      },
      {
        title: "Account Holder Name",
        dataIndex: "accountHolderName",
        key: "accountHolderName",
        width: 200,
        render: (text, record, index) => (
          <span style={{ textTransform: "capitalize" }}>{text}</span>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        width: 100,
        sorter: (a, b) => sortNumber(a, b, "amount"),
      },
      {
        title: "Bank Name",
        dataIndex: "bankName",
        key: "bankName",
        width: 200,
      },
      {
        title: "Account No",
        dataIndex: "accountNumber",
        key: "accountNumber",
        width: 150,
      },
      {
        title: "Sort Code",
        dataIndex: "sortCode",
        key: "sortCode",
        width: 100,
      },
      {
        title: "Requested On",
        dataIndex: "requestedOn",
        key: "requestedOn",
        width: 150,
        sorter: (a, b) => sortNumber(a, b, "requestedOn"),
        render: (text, record, index) => (
          <span>{moment(new Date(text)).format("DD/MM/yy")}</span>
        ),
      },
      {
        title: "Request Status",
        dataIndex: "requestStatus",
        key: "requestStatus",
        width: 150,
        hidden: requestType !== WITHDRAWAL_REQUEST_STATUS.ALL,
        render: (text, record, index) => (
          <span>{handleRequestStatus(text)}</span>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        fixed: "right",
        width: 150,
        hidden: !(
          requestType === WITHDRAWAL_REQUEST_STATUS.REQUEST_SENT ||
          requestType === WITHDRAWAL_REQUEST_STATUS.ALL ||
          requestType === WITHDRAWAL_REQUEST_STATUS.PAYMENT_DUE
        ),
        render: (text, record, index) => (
          <div className="action-button-container">
            {record?.requestStatus ===
              WITHDRAWAL_REQUEST_STATUS.REQUEST_SENT && (
              <>
                <div className="item">
                  <Tooltip placement="top" title="Approve">
                    <Popconfirm
                      title={
                        "Are you sure you want to approve withdraw request"
                      }
                      onConfirm={() =>
                        handleWithDrawRequest(
                          record?.id,
                          WITHDRAW_REQ_STATUS.REQUEST_ACCEPTED
                        )
                      }
                      icon={
                        <QuestionCircleOutlined
                          style={{ color: COLORS.SUCCESS }}
                        />
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        className="ant-btn-sm success-btn flex f-v-center f-h-center"
                        disabled={
                          record.requestStatus !==
                          WITHDRAWAL_REQUEST_STATUS.REQUEST_SENT
                        }
                      >
                        <CheckOutlined className="success-icon" />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </div>
                <div className="item">
                  <Tooltip placement="top" title="Reject">
                    <Popconfirm
                      title={"Are you sure you want to reject withdraw request"}
                      onConfirm={() => handleOpenModal(record)}
                      icon={
                        <QuestionCircleOutlined
                          style={{ color: COLORS.ERROR }}
                        />
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        className="ant-btn-sm flex f-v-center f-h-center"
                        danger
                        type="primary"
                        disabled={
                          record.requestStatus !==
                          WITHDRAWAL_REQUEST_STATUS.REQUEST_SENT
                        }
                      >
                        <CloseOutlined />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </div>
              </>
            )}
            {record?.requestStatus ===
              WITHDRAWAL_REQUEST_STATUS.PAYMENT_DUE && (
              <div className="item">
                <Tooltip placement="top" title="Payment Done">
                  <Popconfirm
                    title={"Payment Done?"}
                    onConfirm={() =>
                      handleWithDrawRequest(
                        record?.id,
                        WITHDRAWAL_REQUEST_STATUS.PAYMENT_DONE
                      )
                    }
                    icon={
                      <QuestionCircleOutlined
                        style={{ color: COLORS.SUCCESS }}
                      />
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      className="ant-btn-sm flex f-v-center f-h-center"
                      type="primary"
                      disabled={
                        record.requestStatus !==
                        WITHDRAWAL_REQUEST_STATUS.PAYMENT_DUE
                      }
                    >
                      <i className="ion-android-done-all" />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
          </div>
        ),
      },
    ].filter((item) => !item.hidden);

    return (
      <Table
        dataSource={withdrawalRequestData.data}
        columns={columns}
        rowKey={"id"}
        style={{ width: "100%" }}
        loading={{ indicator: <Spin />, spinning: isLoading }}
        scroll={{ x: 700 }}
        onChange={tableFiltersUpdated}
        pagination={
          withdrawalRequestData.totalRecords > PAGINATION.PER_PAGE
            ? paginationConfig
            : false
        }
      />
    );
  };

  const handleRequestType = (value) => {
    if (paginationInfo.currentPage !== 1) setPaginationInfo(PAGINATION_INIT);
    setRequestType(value);
  };

  useEffect(() => {
    getWithDrawalData();
  }, [getWithDrawalData, resetData]);

  return (
    <LayoutContentWrapper>
      <LayoutContentStyle>
        <WithdrawalRequestWrapper>
          <div className="title-container">
            <PageHeader>Withdraw Request</PageHeader>
          </div>
          <Segmented
            className="segmented"
            options={[
              {
                value: WITHDRAWAL_REQUEST_STATUS.ALL,
                label: WITHDRAWAL_REQUEST_TYPE.ALL,
              },
              {
                value: WITHDRAWAL_REQUEST_STATUS.REQUEST_SENT,
                label: WITHDRAWAL_REQUEST_TYPE.REQUEST_SENT,
              },
              {
                value: WITHDRAWAL_REQUEST_STATUS.PAYMENT_DUE,
                label: WITHDRAWAL_REQUEST_TYPE.PAYMENT_DUE,
              },
              {
                value: WITHDRAWAL_REQUEST_STATUS.PAYMENT_DONE,
                label: WITHDRAWAL_REQUEST_TYPE.PAYMENT_DONE,
              },
              {
                value: WITHDRAWAL_REQUEST_STATUS.REQUEST_CANCELLED,
                label: WITHDRAWAL_REQUEST_TYPE.REQUEST_CANCELLED,
              },
              {
                value: WITHDRAWAL_REQUEST_STATUS.REQUEST_REJECTED,
                label: WITHDRAWAL_REQUEST_TYPE.REQUEST_REJECTED,
              },
            ]}
            value={requestType}
            onChange={handleRequestType}
          />
          <div className="table-container">{withdrawalRequestListUI()}</div>
          {modalData.visible && (
            <WithdrawalRejectModal
              modalData={modalData}
              closeModal={handleCloseModal}
            />
          )}
        </WithdrawalRequestWrapper>
      </LayoutContentStyle>
    </LayoutContentWrapper>
  );
};

export default WithdrawalRequest;
