import styled from "styled-components";

export const ProfileWrapper = styled.div`
  .form {
    margin-top: 20px;

    .space-compact {
      width: 100%;

      .country-code {
        width: 100px;
      }

      .mobile-no {
        width: calc(100% - 100px);
      }
    }

    .email-input-field{
      color: #000;
    }
  }
`;