//CORE
import React from "react";

//CUSTOM
import { StickerWidgetWrapper } from "./StickerWidget.style";

const StickerWidget = (props) => {
  const { fontColor, bgColor, width, icon, number, text, clickEvent } = props;

  const iconStyle = {
    color: fontColor,
  };

  const textColor = {
    color: fontColor,
  };

  const widgetStyle = {
    backgroundColor: bgColor,
    width: width,
    cursor: clickEvent ? 'pointer' : null
  };

  return (
    <StickerWidgetWrapper style={widgetStyle} className="flex">
      <div className="isoIconWrapper flex f-v-center f-h-center">
        <i className={icon} style={iconStyle} />
      </div>
      <div className="isoContentWrapper flex f-column">
        <h3 className="isoStatNumber" style={textColor}>
          {number}
        </h3>
        <span className="isoLabel" style={textColor}>
          {text}
        </span>
      </div>
    </StickerWidgetWrapper>
  );
};

export default StickerWidget;
