import { COLORS, DIMENSIONS } from "Styles/Constants";
import styled from "styled-components";

import SRInvestmentLogo from "Assets/Images/SRInvestmentLogoBg2.png";
import { borderRadius, transition } from "Styles/StyleUtils";

export const SidebarWrapper = styled.div`
  .isomorphicSidebar {
    z-index: 1000;
    background: ${COLORS.PRIMARY};
    width: 280px;
    flex: 0 0 280px;
    height: 100%;

    .site-icon {
      background-image: url(${SRInvestmentLogo});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      width: 90%;
      height: 40px;
      margin: 15px auto;
      border-radius: 5px;
    }

    .scrollarea {
      height: calc(100vh - 70px);
    }

    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      height: 70px;
      /* background: rgba(0, 0, 0, 0.3); */
      margin: 0;
      padding: 0 10px;
      text-align: center;
      overflow: hidden;
      ${borderRadius()};

      h3 {
        a {
          font-size: 21px;
          font-weight: 300;
          line-height: 70px;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: ${COLORS.GREY};
          display: block;
          text-decoration: none;
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

    .options {
      height: calc(100vh - ${DIMENSIONS.TOP_BAR}px);
      overflow-x: hidden;
      overflow-y: auto;
    }

    .isoDashboardMenu {
      padding-top: 35px;
      padding-bottom: 35px;
      background: transparent;

      a {
        text-decoration: none;
        font-weight: 400;
      }

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 24px;
        margin: 0;
      }

      .isoMenuHolder {
        display: flex;
        align-items: center;

        i {
          font-size: 19px;
          color: inherit;
          margin: ${(props) =>
            props["data-rtl"] === "rtl" ? "0 0 0 30px" : "0 30px 0 0"};
          width: 18px;
          ${transition()};
        }
      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
      }

      .nav-text {
        font-size: 14px;
        color: inherit;
        font-weight: 500;
        ${transition()};
      }

      .child-nav-text {
        font-weight: 500;

        .child-menu-holder {
          display: flex;
          align-items: center;

          i {
            font-size: 19px;
            color: inherit;
            margin: ${(props) =>
              props["data-rtl"] === "rtl" ? "0 0 0 10px" : "0 10px 0 0"};
            width: 18px;
            ${transition()};
          }
        }
      }

      .ant-menu-item-selected {
        /* background-color: rgba(0, 0, 0, 0.4) !important; */
        background: ${COLORS.WHITE};
        color: ${COLORS.BLACK};
        border-radius: 0;

        .anticon {
          color: #fff;
        }

        i {
          color: ${COLORS.BLACK};
        }

        .nav-text {
          color: #fff;
        }
      }

      > li {
        &:hover {
          i,
          .nav-text {
            /* color: #ffffff;   */
          }
        }
      }
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: ${COLORS.PRIMARY_DARK};
    }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 24px;

        > span {
          display: flex;
          align-items: center;
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          background: rgba(0, 0, 0, 0.3);
          border-radius: 0;
          padding-left: ${(props) =>
            props["data-rtl"] === "rtl" ? "0px !important" : "74px !important"};
          padding-right: ${(props) =>
            props["data-rtl"] === "rtl" ? "74px !important" : "0px !important"};
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: inherit;
          /* ${transition()}; */

          &:hover {
            color: #ffffff;
          }

          &.ant-menu-item-selected {
            background: ${COLORS.WHITE};
            color: ${COLORS.BLACK};

            &:hover {
              background: ${COLORS.WHITE};
              color: ${COLORS.BLACK};
            }
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;
      }
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }

      .ant-menu-submenu-inline > {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }
  }
`;
