//CORE
import { useContext, useEffect, useRef, useState } from "react";
import { Avatar, Badge, Card, Spin, Typography } from "antd";
// import moment from "moment";

//TYPE
import {
  LayoutContentStyle,
  LayoutContentWrapper,
  PageHeader,
} from "Components/Common/Common.style";

//ICONS
import { NoDataIcon } from "Assets/Icons/SRInvestmentIcons";

//CUSTOM
import { SocketContext } from "Context/Socket/SocketContext";
import { SOCKET_EVENTS } from "Helpers/Paths";
import { ChatWrapper } from "./Chat.style";
import { useNavigate } from "react-router-dom";
import { stringToColor } from "Helpers/Utils";
import CODES from "Helpers/StatusCodes";
import { CHAT_MSG_USERS } from "Helpers/Constants";

const Chat = () => {
  const socketTextMsg = useContext(SocketContext);
  const userListInnerRef = useRef(null);
  const navigate = useNavigate();

  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 0,
    perPage: 50,
  });
  const [userList, setUserList] = useState({
    data: [],
    totalRecords: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  // const handleRoleType = (roleId) => {
  //   switch (roleId) {
  //     case CHAT_MSG_USERS.CUSTOMER:
  //       return " (Customer)";
  //     default:
  //       return " (Admin)";
  //   }
  // };

  // const getUserName = (data) => {
  //   return data?.sendFrom === "ADMIN"
  //     ? data?.receiverFullName
  //     : data?.senderFullName;
  // };

  const handleScrollPagination = () => {
    if (userListInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        userListInnerRef.current;
      if (
        scrollTop + clientHeight === scrollHeight &&
        userList?.data.length < userList.totalRecords
      ) {
        setPaginationInfo((prev) => ({
          ...prev,
          currentPage: prev.currentPage + 1,
        }));
      }
    }
  };

  useEffect(() => {
    socketTextMsg.open();
    setIsLoading(true);
    const timestamp = new Date().getTime();
    // const timestamp = moment().unix();
    const dataToSend = {
      roleType: CHAT_MSG_USERS.CUSTOMER,
      page: {
        limit: paginationInfo.perPage,
        pageId: paginationInfo.currentPage,
      },
      offset: timestamp,
      // offset: timestamp.toString.length < 13 ? timestamp + "0000" : timestamp,
    };
    socketTextMsg.emit(SOCKET_EVENTS.LIST.THREAD, dataToSend, (data) => {
      setIsLoading(false);
      if (
        data?.body?.status === CODES.SUCCESS &&
        !!data?.body?.data?.threadDataList.length
      )
        setUserList((prev) => {
          const arr =
            paginationInfo.currentPage === 0
              ? data?.body?.data?.threadDataList
              : prev.data.concat(data?.body?.data?.threadDataList);
          return {
            data: [...new Map(arr.map((item) => [item["id"], item])).values()],
            totalRecords: data?.body?.data?.totalRecords,
          };
        });
    });

    return () => {
      socketTextMsg.close();
    };
  }, [paginationInfo, socketTextMsg]);

  return (
    <LayoutContentWrapper>
      <LayoutContentStyle>
        <ChatWrapper>
          <div className="title-container">
            <PageHeader>Chat</PageHeader>
          </div>
          <Spin spinning={isLoading}>
            <div
              className="user-list-section"
              ref={userListInnerRef}
              onScroll={handleScrollPagination}
            >
              {!!userList?.data.length ? (
                userList?.data.map((item) => (
                  <Card
                    className="card flex"
                    key={item?.id}
                    onClick={() =>
                      navigate(`/chat/${item?.senderFullName}`, {
                        state: {
                          msgId: item?.id,
                          // sendFrom: item?.sendFrom,
                          userName: item?.senderFullName,
                          msgReceiverId: item?.senderId,
                        },
                      })
                    }
                  >
                    <div className="user-content flex f-v-center f-h-space-between">
                      <Badge count={item?.unreadMessageCount}>
                        <Avatar
                          size="large"
                          className="user-avatar"
                          style={{
                            backgroundColor: stringToColor(
                              item?.senderFullName
                            ),
                          }}
                        >
                          <span className="avatar-text">
                            {item?.senderFullName.split(" ")[0][0] +
                              (item?.senderFullName.split(" ")[1]
                                ? item?.senderFullName.split(" ")[1][0]
                                : "")}
                          </span>
                        </Avatar>
                      </Badge>
                      <div className="user-details">
                        <div className="username">{item?.senderFullName}</div>
                        <div className="msg">{item?.preview}</div>
                      </div>
                    </div>
                  </Card>
                ))
              ) : (
                <div className="no-data flex f-column f-v-center">
                  <NoDataIcon />
                  <Typography>No Chat</Typography>
                </div>
              )}
            </div>
          </Spin>
        </ChatWrapper>
      </LayoutContentStyle>
    </LayoutContentWrapper>
  );
};

export default Chat;
