//CORE
import React, { useMemo, useRef, useState } from "react";
import { Button, Form, Input, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import { ForgotPasswordWrapper } from "./ForgotPassword.style";
import { API_URL, URL_LOGIN } from "Helpers/Paths";
import { ROLE_ID } from "Helpers/Constants";
import CODES from "Helpers/StatusCodes";

const ForgotPassword = () => {
  const formRef = useRef(null);
  const navigate = useNavigate()
  const API = useMemo(() => new Api(), []);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await API.post(API_URL.FORGOT_PASSWORD, {
        data: {
          emailId: values.email,
          roleId: ROLE_ID.ADMIN,
        },
      });
      if (response.status === CODES.SUCCESS) {
        notification.success({
          message: "Success",
          description: response?.message,
        });
        navigate({ pathname: URL_LOGIN });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <ForgotPasswordWrapper className="flex f-v-center f-h-center">
      <div className="form-wrapper">
        <div className="logo"></div>
        <Form
          ref={formRef}
          onFinish={handleSubmit}
          scrollToFirstError
          layout="vertical"
          className="forgot-password-form"
        >
          <Form.Item
            name="email"
            label="Email"
            className="form-item"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter valid email.",
              },
            ]}
          >
            <Input
              className="input-field"
              autoFocus
              size="large"
              placeholder="Email"
            />
          </Form.Item>
          <p className="login-text">
            <Link to={URL_LOGIN}>Login?</Link>
          </p>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="forgot-password-btn"
          >
            Forgot Password
          </Button>
        </Form>
      </div>
    </ForgotPasswordWrapper>
  );
};

export default ForgotPassword;
