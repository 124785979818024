import { COLORS } from "Styles/Constants";
import styled from "styled-components";

export const TextMsgWrapper = styled.div`
  width: fit-content;
  max-width: 60%;
  padding: 5px;

  @media only screen and (max-width: 767px) {
    max-width: 70%;
  }

  @media only screen and (max-width: 450px) {
    max-width: 80%;
  }

  &.admin-msg {
    border-radius: 10px 0 10px 10px;
    background: ${COLORS.PRIMARY};
    color: #ffffff;
    margin-left: auto;
  }

  &.user-msg {
    border-radius: 0 10px 10px 10px;
    border: 1px solid ${COLORS.PRIMARY};
    color: ${COLORS.PRIMARY};
  }
`;
