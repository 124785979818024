//CORE
import { useCallback, useEffect, useMemo, useState } from "react";
import { Spin, Table } from "antd";

//API
import Api from "Helpers/ApiHandler";

//ICONS
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

//CUSTOM
import {
  LayoutContentStyle,
  LayoutContentWrapper,
  PageHeader,
} from "Components/Common/Common.style";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
// import { PAGINATION } from "Helpers/Constants";
import { COLORS } from "Styles/Constants";

const Subscription = () => {
  const API = useMemo(() => new Api(), []);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({
    data: [],
    totalRecords: 0,
  });
  // const [paginationInfo, setPaginationInfo] = useState({
  //   currentPage: 1,
  //   perPage: PAGINATION.PER_PAGE,
  //   filter: {
  //     field: "id",
  //     order: "ASC",
  //   }
  // });

  const getSubscriptionPlan = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await API.get(API_URL.SUBSCRIPTION_PLAN);
      if (response.status === CODES.SUCCESS) {
        setIsLoading(false);
        setSubscriptionData({
          data: response?.data,
          totalRecords: response.data[0].perks.length,
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [API]);

  const subscriptionPlanListUI = () => {
    const data = [];

    const columns = [
      {
        title: "Feature",
        dataIndex: "perkName",
        key: "perkName",
        width: 200,
      },
    ];

    for (let i = 0; i < subscriptionData?.data.length; i++) {
      columns.push({
        title: subscriptionData?.data[i].planName,
        dataIndex: subscriptionData?.data[i].planName + "value",
        key: subscriptionData?.data[i].planName + "value",
        width: 100,
        render: (text, record, index) =>
          text ? (
            <CheckOutlined style={{ color: COLORS.SUCCESS }} />
          ) : (
            <CloseOutlined style={{ color: COLORS.ERROR }} />
          ),
      });
      for (let j = 0; j < subscriptionData?.data[i].perks.length; j++) {
        data.push({
          perkNumber: subscriptionData?.data[i].perks[j].perkNumber,
          perkName: subscriptionData?.data[i].perks[j].perkName,
          [subscriptionData?.data[i].planName + "value"]:
            subscriptionData?.data[i].perks[j].perkValue,
        });
      }
    }

    const mergedData = {};

    // Iterate through the array and merge objects with the same perkNumber
    data.forEach((item) => {
      const perkNumber = item.perkNumber;
      if (!mergedData[perkNumber]) {
        // If the perkNumber is not in the mergedData, add it with the current item
        mergedData[perkNumber] = { perkNumber };
      }
      // Merge the current item into the existing mergedData object
      mergedData[perkNumber] = { ...mergedData[perkNumber], ...item };
    });

    // Convert the mergedData object back into an array of objects
    const mergedArray = Object.values(mergedData);

    return (
      <Table
        dataSource={mergedArray}
        columns={columns}
        rowKey={"perkNumber"}
        style={{ width: "100%" }}
        loading={{ indicator: <Spin />, spinning: isLoading }}
        scroll={{ x: 700 }}
      />
    );
  };

  useEffect(() => {
    getSubscriptionPlan();
  }, [getSubscriptionPlan]);

  return (
    <LayoutContentWrapper>
      <LayoutContentStyle>
        <div className="title-container">
          <PageHeader>Subscription Plan</PageHeader>
        </div>
        <div className="table-container">{subscriptionPlanListUI()}</div>
      </LayoutContentStyle>
    </LayoutContentWrapper>
  );
};

export default Subscription;
