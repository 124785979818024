//CORE
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Spin, Table, Tooltip } from "antd";

//WRAPPER
import {
  LayoutContentStyle,
  LayoutContentWrapper,
  PageHeader,
} from "Components/Common/Common.style";

//ICONS
import { EditOutlined } from "@ant-design/icons";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import { PAGINATION } from "Helpers/Constants";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import { sortNumber } from "Helpers/Utils";
import EmailTemplateModal from "./Modal/EmailTemplateModal";

const Email = () => {
  const API = useMemo(() => new Api(), []);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    perPage: PAGINATION.PER_PAGE,
    filter: {
      field: "id",
      order: "ASC",
    },
  });
  const [emailData, setEmailData] = useState({
    data: [],
    totalRecords: 0,
  });
  const [emailModal, setEmailModal] = useState({
    data: null,
    visible: false,
  });
  const [resetData, setResetData] = useState(false);

  const getEmailList = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await API.post(API_URL.GET_EMAIL_TEMPLATE, {
        data: {
          pageNumber: paginationInfo.currentPage,
          totalRecordPerPage: paginationInfo.perPage,
          orderBy: paginationInfo.filter.field,
          order: paginationInfo.filter.order,
        },
      });
      if (response.status === CODES.SUCCESS) {
        setEmailData({
          data: response?.data?.list,
          totalRecords: response.data.totalElements,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [API, paginationInfo]);

  const handleOpenModal = (data) => {
    setEmailModal((prev) => {
      return { ...prev, data, visible: true };
    });
  };

  const handleCloseModal = (shouldUpdate = false) => {
    setEmailModal((prev) => {
      return { ...prev, data: null, visible: false };
    });
    shouldUpdate && setResetData((prev) => !prev);
  };

  const tableFiltersUpdated = (pagination, filters, sorter) => {
    let sorterOrder = "ASC";
    if (sorter.field)
      switch (sorter.order) {
        case "ascend":
          sorterOrder = "ASC";
          break;
        case "descend":
          sorterOrder = "DESC";
          break;
        default:
          sorterOrder = "ASC";
          sorter.field = "id";
          break;
      }

    setPaginationInfo((prev) => {
      return {
        ...prev,
        currentPage: pagination.current ? pagination.current : 1,
        perPage: pagination?.pageSize || PAGINATION.PER_PAGE,
        filter: {
          field: sorter.field || "id",
          order: sorterOrder || "Asc",
        },
      };
    });
  };

  const emailListUI = () => {
    const paginationConfig = {
      pageSize: paginationInfo.perPage,
      total: emailData.totalRecords,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} records`,
      current: paginationInfo.currentPage,
      showSizeChanger: true,
    };

    const columns = [
      {
        title: "Sr.No",
        dataIndex: "id",
        key: "id",
        rowScope: "row",
        fixed: "left",
        width: 70,
        render: (text, record, index) => (
          <span>
            {(paginationInfo.currentPage - 1) * paginationInfo.perPage +
              index +
              1}
          </span>
        ),
      },
      {
        title: "Type",
        dataIndex: "emailType",
        key: "emailType",
        width: 400,
        sorter: (a, b) => sortNumber(a, b, "emailType"),
      },
      {
        title: "Subject",
        dataIndex: "title",
        key: "title",
        width: 350,
        sorter: (a, b) => sortNumber(a, b, "title"),
      },
      {
        title: "Action",
        key: "action",
        fixed: "right",
        width: 100,
        render: (text, record, index) => (
          <div className="action-button-container">
            <div className="item">
              <Tooltip placement="top" title="Edit">
                <Button
                  className="ant-btn-sm"
                  onClick={() => handleOpenModal(record)}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            </div>
          </div>
        ),
      },
    ];

    return (
      <Table
        dataSource={emailData.data}
        columns={columns}
        style={{ width: "100%" }}
        loading={{ indicator: <Spin />, spinning: isLoading }}
        scroll={{ x: 700 }}
        onChange={tableFiltersUpdated}
        rowKey={"id"}
        pagination={
          emailData.totalRecords > PAGINATION.PER_PAGE
            ? paginationConfig
            : false
        }
      />
    );
  };

  useEffect(() => {
    getEmailList();
  }, [getEmailList, resetData]);

  return (
    <LayoutContentWrapper>
      <LayoutContentStyle>
        <div className="title-container">
          <PageHeader>Email</PageHeader>
        </div>
        <div className="table-container">{emailListUI()}</div>
        {emailModal.visible && (
          <EmailTemplateModal
            modalData={emailModal.data}
            closeModal={handleCloseModal}
          />
        )}
      </LayoutContentStyle>
    </LayoutContentWrapper>
  );
};

export default Email;
