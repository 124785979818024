//CORE
import { useMemo, useRef, useState } from "react";
import { Button, Form, Input, Modal, Spin, notification } from "antd";

//API
import Api from "Helpers/ApiHandler";

//CUSTOM
import { FormWrapper } from "Components/Common/Common.style";
import { FORM_PARAMS } from "Helpers/Constants";
import { API_URL } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";

const EditSocialMedia = (props) => {
  const { modalData } = props;
  const API = useMemo(() => new Api(), []);
  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await API.put(API_URL.SOCIAL_MEDIA.EDIT, {
        data: { ...values, id: modalData.id },
      });
      if (response.status === CODES.SUCCESS) {
        setIsLoading(false);
        notification.success({
          message: "Success",
          description: response?.message,
        });
        handleClose(true)
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = (updateFlag = false, data = null) => {
    props.closeModal(updateFlag, data);
  };

  return (
    <Modal
      open
      title={"Edit Transaction Log"}
      okButtonProps={{ disabled: isLoading }}
      onCancel={() => handleClose()}
      footer={[
        <Button key="back" type="default" onClick={() => handleClose()}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => formRef.current?.submit()}
        >
          Update
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <FormWrapper
          ref={formRef}
          onFinish={handleSubmit}
          scrollToFirstError
          labelAlign="left"
          labelCol={{ span: FORM_PARAMS.LABEL_COL_SPAN }}
          labelWrap
          initialValues={{
            name: modalData?.name,
            url: modalData?.url,
          }}
        >
          <Form.Item name="name" key="name" label="Name">
            <Input placeholder="Enter social media name" disabled />
          </Form.Item>
          <Form.Item
            name="url"
            key="url"
            label="Url"
            rules={[
              {
                required: true,
                message: "Please enter social media url",
              },
            ]}
          >
            <Input placeholder="Enter social media url" />
          </Form.Item>
        </FormWrapper>
      </Spin>
    </Modal>
  );
};

export default EditSocialMedia;
