//ROUTE BEFORE LOGIN
export const URL_LOGIN = "/login";
export const URL_SIGN_IN = "/";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_RESET_PASSWORD = "/reset-password";
export const URL_TERMS_AND_CONDITIONS = "/terms-and-conditions"
export const URL_PRIVACY_POLICY = "/privacy-policy"
export const URL_MINIMUM_INVESTMENT_AMOUNT = "/minimum-investment-amount"

//ROUTE AFTER LOGIN
export const URL_DASHBOARD = "/dashboard";
export const URL_REGISTERED_USER = "registered-user";
export const URL_VERIFIED_USER = "verified-user";
export const URL_EMAIL_TEMPLATE = "email-template";
export const URL_USER_NOTIFICATION = "user-notification";
export const URL_ADMIN_PROFILE = "/profile";
export const URL_SUBSCRIPTION_PLAN = "subscription-plan";
export const URL_TRANSACTION_LOG = "/transaction-log";
export const URL_STATIC_PAGE = "static-page";
export const URL_WITHDRAWAL_REQUEST = "withdrawal-requests";
export const URL_SOCIAL_MEDIA = "social-media-contact";
export const URL_NOTIFICATION = "notification";
export const URL_CHAT = "chat";
export const URL_FEEDBACK = "feedback";

export const API_URL = {
  LOGOUT: "user/v1/cms/logout",
  LOGIN: "user/v1/cms/login",
  FORGOT_PASSWORD: "user/v1/cms/forgotPassword",
  RESET_PASSWORD: "user/v1/cms/resetPassword",
  GET_EMAIL_TEMPLATE: "v1/cms/email-template-list",
  EDIT_EMAIL_TEMPLATE: "v1/cms/edit-title-content-of-email",
  SEND_USER_NOTIFICATION: "v1/cms/custom-email-notification",
  GET_REGISTERED_USER: "user/v1/cms/registered-customer-list",
  GET_VERIFIED_USER: "user/v1/cms/verified-customer-list",
  ADD_USER: "user/v1/cms/create-customer",
  EDIT_USER: "user/v1/cms/edit-customer-info",
  SUBSCRIPTION_PLAN: "subscription-plan/cms/fetch-subscription-plans",
  CSV: {
    UPLOAD_OLD_USER: "funds/v1/cms/upload-existing-customer-investment",
    VERIFIED_USER:
      "funds/v1/cms/update-customer-invested-funds-current-value-through-csv",
  },
  UPDATE_INVESTMENT_LOG:
    "funds/v1/cms/update-customer-invested-funds-current-value",
  GET_INVESTMENT_LOG: "funds/v1/cms/fetch-user-investment-details",
  STATIC_PAGE: {
    GET: "static-content/v1/cms/list-of-static-page",
    EDIT: "static-content/v1/cms/save-static-page-content",
    GET_WITHOUT_SIGN_IN: "static-content/v1/cms/get-static-page-before-sign-in"
  },
  WITHDRAWAL_REQUEST: {
    GET: "withdrawal-request/v1/cms/fetch-amount-withdrawal-requests",
    ACTION: "withdrawal-request/v1/cms/action-on-withdrawal-request",
  },
  SOCIAL_MEDIA: {
    GET: "static-content/v1/app/fetch-social-media-contacts",
    EDIT: "static-content/v1/cms/edit-social-media-contact",
  },
  NOTIFICATION: {
    GET: "notification/v1/cms/push-notification-type-list",
    EDIT: "notification/v1/cms/edit-push-notification-type",
  },

  ADMIN: {
    GET: "user/v1/cms/admin-profile-info",
    EDIT: "user/v1/cms/edit-admin-profile",
  },
  FEEDBACK: {
    GET: "feedback/v1/cms/fetch-user-feedbacks",
    UPDATE_STATUS: "feedback/v1/cms/change-feedback-status"
  },
  DASHBOARD: {
    GET: "funds/v1/cms/fetch-dashboard-data",
  },
  CHAT: {
    SEND_MSG: "chat/v1/app-cms/send-message"
  }
};

export const SOCKET_EVENTS = {
  SEND_MSG: "message/send",
  GET_MSG: "message/receive",
  LIST: {
    MSG: "message/list",
    THREAD: "cms/thread/list",
  },
  TEXT: {
    MSG_LIST: "text/message/list",
    THREAD_LIST: "text/thread/list",
  },
  MESSAGE: {
    LAST_READ: "message/lastReadMessage",
    UPDATE_MSG: "message/updatedMessages",
  },
};
