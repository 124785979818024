import { COLORS } from "Styles/Constants";
import styled from "styled-components";

export const UserChatWrapper = styled.div`
  padding: 40px 20px;
  height: calc(100vh - 70px - 70px);

  /* @media only screen and (max-width: 767px) {
    height: calc(100vh - 60px - 50px);
  } */

  .content {
    background-color: #ffffff;
    border: 1px solid ${COLORS.BORDER};
    width: 100%;
    height: 100%;
    padding: 35px;
    overflow: auto;

    .user-content {
      align-items: center;
      gap: 10px;

      .back-icon {
        cursor: pointer;
      }

      .user-name {
        font-size: 18px;
        font-weight: 500;
      }

      .close-btn-tooltip {
        margin: 0 0 0 auto;

        .close-icon {
          font-size: 18px;
        }
      }
    }

    .container {
      overflow: auto;
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
      overflow: auto;
      padding: 5px;
      margin: 10px 0;

      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      ::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: rgba(162, 162, 162, 0.5);
        border-radius: 4px;
      }

      .msg-content {
        display: flex;
        flex-direction: column-reverse;

        .msg-wrapper {
          margin-bottom: 10px;

          :last-child {
            margin-bottom: 0;
          }

          .date-group-wrapper {
            .date-group-text {
              margin: 0;
              padding: 5px 10px;
              border-radius: 10px;
              background-color: ${COLORS.GREY};
              box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.5);
            }
          }
        }

        .msg-time {
          margin: 0;
          font-size: 10px;

          &.admin-time {
            margin-left: auto;
          }
        }
      }
    }

    .input-content {
      margin: auto 0 0 0;
      gap: 10px;

      .input-wrapper {
        width: 100%;

        .input-msg {
          border-radius: 10px;
          padding: 10px;

          .suffix-icon {
            font-size: 18px;
            margin-right: 5px;
            transform: rotateZ(45deg);
            color: ${COLORS.PRIMARY};
            cursor: pointer;
          }
        }
      }

      .send-wrapper {
        background: ${COLORS.PRIMARY};
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;
        height: 50px;
        align-self: flex-end;

        .send-icon {
          color: #fff;
          font-size: 30px;
        }
      }
    }
  }
`;
