//CORE
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

//CUSTOM
import { URL_LOGIN } from "Helpers/Paths";
import { SocketProvider } from "Context/Socket/SocketContext";

const ProtectedRoute = (props) => {
  const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);

  return (
    <div style={{ height: "inherit" }}>
      {!isLoggedIn ? (
        <Navigate replace to={URL_LOGIN} />
      ) : (
        <SocketProvider>{props.children}</SocketProvider>
      )}
    </div>
  );
};

export default ProtectedRoute;
